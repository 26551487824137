<div class="item-detail">
  <div *ngIf="isLoading" class="nodata">
    <i nz-icon nzType="loading" nzTheme="outline"></i>
  </div>

  <ng-container *ngIf="!isLoading">
    <peak-info [peakInfo]="peakInfo" [peakIndex]="peakIndex"></peak-info>
    <network-chart [stops]="stops" [maxWeight]="maxWeight" [maxQuantity]="maxQuantity" [perPage]="perPage"></network-chart>
  </ng-container>
</div>
