<form *ngIf="!isLoadingWarehouseTasks" class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Cross Dock Shipment Information</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- cancel button with confirmation -->
        <button (nzOnConfirm)="onBtnCancel()"
          *ngIf="isEditing && isInputsChange()"
          [disabled]="onProgress"
          class="btn-primary right10"
          nz-button
          nz-popconfirm
          nzPopconfirmPlacement="top"
          nzOkText="Yes"
          nzCancelText="No"
          nzPopconfirmTitle="Are you sure you want to cancel editing?"
        >
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- cancel button -->
        <button (click)="onBtnCancel()"
          *ngIf="isEditing && !isInputsChange()"
          [disabled]="onProgress"
          class="btn-primary right10"
          nz-button
        >
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- save button -->
        <button
          nz-button
          nzType="primary"
          (click)="onBtnSave()"
          class="btn-primary"
          *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate"
          [nzLoading]="onProgress"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content">
      <div class="tdm-row cell-spacing">
        <div *ngFor="let mode of taskSettings;" [formArrayName]="mode.type" class="tdm-col tdm-col-2">
          <label class="title">{{getLabel(fullKey(mode.type))}} </label>

          <div *ngFor="let item of getArrayControls(mode.type); let i = index" class="form-setting index-{{ i }}">
            <form nz-form [formGroupName]="i">
              <div class="tdm-row cell-spacing">
                <div class="tdm-col tdm-col-2" *ngFor="let childKey of ['taskType', 'levels'];">
                  <div>
                    <ng-container *ngIf="i === 0">
                      <div class="form-label-v2">
                        {{getLabel(fullKey(mode.type, childKey))}}
                        <span *ngIf="isRequired(fullKey(mode.type, childKey))" class="label-mark-required"></span>
                      </div>
                    </ng-container>
                    <ng-container [ngSwitch]="childKey">
                      <div *ngSwitchCase="'taskType'" [ngClass]="childKey">
                        <ng-container *ngIf="getLevels(mode, i, 'taskType').length">
                          <label>{{ getTaskName(mode.type, i, childKey) }}</label>
                          <input
                            nz-input
                            [formControlName]="childKey"
                            [type]="getInputType(childKey)"
                            [placeholder]="getPlaceHolder(childKey)"
                            (input)="onInputChanged($event, childKey)"
                            (keypress)="onInputKeyPress($event, childKey)"
                          />
                        </ng-container>
                      </div>

                      <div *ngSwitchCase="'levels'" [ngClass]="childKey">
                        <form nz-form [formGroupName]="childKey" class="group-crossdock">
                          <div class="item-crossdock" *ngFor="let key of getLevels(mode, i, 'taskType')">
                            <nz-form-item>
                              <nz-form-control>
                                <ng-container *ngIf="!isEditing">
                                  <label nz-checkbox 
                                    [formControlName]="key"
                                  >{{getLabel(fullKey(mode.type, childKey, key))}}</label>
                                </ng-container>

                                <ng-container *ngIf="isEditing">
                                  <label nz-checkbox 
                                    [nzDisabled]="isDisable(mode, i, 'taskType')" 
                                    [formControlName]="key"
                                  >{{getLabel(fullKey(mode.type, childKey, key))}}</label>
                                </ng-container>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                        </form>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
