import { Component, ViewChild } from '@angular/core';
import { Const } from '@const/Const';
import { DialogService } from '@dialogs/dialog.service';
import { PlanningRouteDialog } from '@app/admin/planning/dialog';
import { ApiService } from '@services/api.service';
import { Utils as ServiceUtils } from "@services/utils";
import { ModalHelper, UpdateTimeWindows } from '@wearewarp/ng-antd';
import { UIHelper } from '@services/UIHelper';
import { MasterData } from '@services/master.data';
import { merge } from 'rxjs/operators';
import { DlgCreateRoute } from '../shipments/dlg-create-route';
import { BaseList } from '@app/admin/base/list';
import { DlgChecklistItems } from '../shipments/dlg-checklist-items';
import { WarehouseShipmentFilter } from '../shipments/filter';
import { WarehouseShipmentFilterExtend } from '../shipments/filter-extend';

@Component({
    selector: '[warehouse-item-barcode-issues]',
    templateUrl: './view.html',
    styleUrls: ['./style.scss']
})
export class WarehouseItemBarcodeIssues extends BaseList {
    public warehouseId = this.getQueryParam('warehouseId');
    private defaultPageSize = 500
    public selectedTabIndex = 0;
    public tabs = [
        { title: 'Open', value: Const.IssueTicketStatus.OPEN },
        { title: 'Closed', value: Const.IssueTicketStatus.CLOSED }
    ]

    get limit() {
        return this.defaultPageSize
    }

    @ViewChild('filter') filter: WarehouseShipmentFilter;

    constructor(private modalHelper: ModalHelper) {
        super()
        this.onRouteCreated = this.onRouteCreated.bind(this)
        this.onRefresh = this.onRefresh.bind(this)
    }

    onRouteCreated(data: any) {
        this.modalService.create({
            nzContent: DlgCreateRoute,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzKeyboard: false,
            nzComponentParams: {
                route: data,
                onSuccess: this.onRefresh
            }
        });
    }

    onRefresh() {
        this.onBtnRefresh()
    }

    private _rawOptions: any = {
        status: [],
        clients: [],
        pickupLocations: [],
        deliveryLocations: [],
        deliveryStates: [],
        pickupStates: [],
        originalPickupLocations: [],
        finalDeliveryLocations: [],
    }
    public data: any[]
    public options: any = ServiceUtils.cloneObject(this._rawOptions);
    public summary: any = {
        totalRecords: 0,
        totalPallets: 0,
        records: 0,
        pallets: 0
    }
    condition: any = {};
    selectedPalletCount: number = 0
    public checkedAll = false;
    public indeterminate = false;
    public defaultStatus = [
        Const.WarehouseJobStatus.pending,
        Const.WarehouseJobStatus.readyForPickup,
        Const.WarehouseJobStatus.scheduledToPickup,
        Const.WarehouseJobStatus.pickedUp,
        Const.WarehouseJobStatus.arrivedAtWarehouse,
        Const.WarehouseJobStatus.readyForOutbound
    ];

    ngOnInit(): void {
        this.autoShrinkSideBar();
        this.getCrossdockWarehouseList(); // lấy list warehouse để select
    }

    public crossdockWarehouseList = []; //list warehouse hiển thị trong selectbox
    isLoadingWarehouseList = false

    getCrossdockWarehouseList() {
        this.isLoadingWarehouseList = true;
        this.api.GET(Const.APIURI_SELECT_WAREHOUSES).subscribe(
            resp => {
                if (resp?.data?.list_data && resp.data.list_data.length) {
                    this.crossdockWarehouseList = resp.data.list_data.map((item: any) => {
                        let { id, warpId, name } = item;
                        return {
                            id, warpId, name, label: `${warpId} - ${name}`
                        }
                    });
                }
                this.isLoadingWarehouseList = false;
            }, err => {
                this.isLoadingWarehouseList = false;
            }
        )
    }

    onChangeWarehouse(value) {
        this.routeWithQueryUrl({ warehouseId: value, page: 1 })
    }

    protected getApiUrl(): string {
        return `${Const.API_SERVICE_WAREHOUSE}/admin/issues/getList`;
    }

    protected getDataByUrl(url: string): void {
        // if (!this.warehouseId) return
        let options = { };
        this.isLoading = true;
        this.subApi?.unsubscribe();

        let params: any = {}
        if (this.warehouseId) {
            params = {
                warehouseId: this.warehouseId
            }
        }
        params.status = this.tabs[this.selectedTabIndex].value;
        // add params to url
        url = url + '&' + new URLSearchParams(params).toString();
        this.subApi = this.api.GET(url, options).subscribe(
            resp => {
                console.log('get list data done: ', resp);
                this.getDataDone(resp);
                this.isLoading = false
            }, err => {
                this.showErr(err)
                this.isLoading = false
            }
        );
    }

    protected onGetDataSucceeded(resp) {
        this._handleResponse(resp.data)
    }

    private _handleResponse(data: any) {
        let lists: any = data?.list_data || [];
        lists = this._formatDataResponse(lists);
        this.summary = {
            totalRecords: data.total,
            records: lists.length,
        }
        this.data = [...lists]
    }

    private _formatDataResponse(lists: any) {
        return lists;
    }

    expandSet = new Set<string>();
    onExpandChange(row: any): void {
        if (!this.expandSet.has(row.id)) {
            for (let x of this.data) {
                if (this.expandSet.has(x.id)) {
                    x.expanded = false
                }
            }
            this.expandSet.clear()
            row.expanded = true
            this.expandSet.add(row.id);
        } else {
            row.expanded = false
            this.expandSet.delete(row.id);
        }
    }

    onTabChange(index: number) {
        this.selectedTabIndex = index
        this.onBtnRefresh();
    }

    trackByRow(index: number, row: any) {
        return row.id;
    }
}
