import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Utils as ServiceUtils } from "@services/utils";
import { Const as ConstUniversal } from '@wearewarp/universal-libs';
import { ReportItemIssue, IssueTicketType, IssueTicketStatus } from '@wearewarp/types/data-model';

@Component({
    selector: '[warehouse-item-barcode-issues-list-item]',
    templateUrl: './view.html',
    styleUrls: ['./style.scss']
})
export class WarehouseItemBarcodeIssuesListItem extends BaseComponent {
    _item: any;

    @Output() onItemSelected: EventEmitter<any> = new EventEmitter<any>();

    @Input() selected = false;
    @Input() warehouses: {id: string, warpId: string, name: string}[];

    @Input() set item(value) {
        this._item = value;
    }

    onSelect() {
        if (this._item) {
            this.onItemSelected.emit(this._item)
        }
    }

    stopPropergate(event) {
        event.stopPropagation();
    }

    copyToClipboard(event, txt) {
        event.stopPropagation()
        ServiceUtils.copyTextToClipboard(txt, (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied to the clipboard ${txt}`
                );
            }
        })
    }

    getListBarcodes(item) {
        return item?.data?.barcodes || [];
    }

    getWarehouseName(warehouseId: string) {
        return this.warehouses.find(w => w.id === warehouseId)?.name || '';
    }

    getListInternalBarcodes(item) {
        return item?.data?.warpBarcodes || [];
    }

    getIssueText(type: IssueTicketType): string{
        const issueTypeMap: Record<IssueTicketType, string> = {
            'PALLET_DAMAGE': 'Pallet Damage',
            'PALLET_MISSING': 'Pallet Missing',
            'PALLET_DISCREPANCY': 'Pallet Discrepancy',
            'PALLET_UNRECOGNIZED': 'Pallet Unrecognized',
            'PALLET_WRONG_WAREHOUSE': 'Pallet Wrong Warehouse'
        }
        return issueTypeMap[type] || (type as string);
    }

    getIssuesStatus(status: IssueTicketStatus): IssueTicketStatus{
        return status;
    }

    getStatusColor(status: IssueTicketStatus): string {
        const colors = {
            'OPEN': 'red',
            'IN_PROGRESS': 'blue',
            'NEED_CONFIRM': 'orange',
            'CLOSED': 'green'
        };
        return colors[status] || 'default';
    }

    getIssueLevel(item: ReportItemIssue) {
        return item?.level || '';
    }
}
