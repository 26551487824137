import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Shipment } from "@wearewarp/types/data-model";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { jsPDF } from "jspdf"; //sử dụng để tạo PDF
import * as pdfjsLib from "pdfjs-dist"; //sử dụng để đọc file PDF
import { OrderDisplayInfo } from "@app/admin/components/quick-upload-photos/quickUploadPhoto.interface";
import { QuickUploadPhotoService } from "@app/admin/components/quick-upload-photos/quickUploadPhoto.service";
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";

@Injectable({
  providedIn: 'root'
})
export class ItemIssueService {
  public activeTabIndex: BehaviorSubject<number> = new BehaviorSubject(0);
  public selectedIssueTicket: BehaviorSubject<any> = new BehaviorSubject(null);
  public tabs: BehaviorSubject<any[]> = new BehaviorSubject([
    {
      name: 'Search',
      closable: false,
      type: "search"
    },
    // {
    //   name: 'Search AI',
    //   closable: false,
    //   type: "search-ai"
    // },
  ]);


  constructor(private api: ApiService, private quickUploadPhotoService: QuickUploadPhotoService) {

  }

  setSelectedIssueTicket(value: any) {
    this.selectedIssueTicket.next(value);
  }

  getSelectedIssueTicket() {
    return this.selectedIssueTicket.getValue();
  }

  openOrderDetail(order: OrderDisplayInfo) {
    const indexOfExistTab = this.tabs.getValue().findIndex(tab => tab.type === "order-detail" && tab.data.orderId === order.orderId);
    if (indexOfExistTab !== -1) {
      this.activeTabIndex.next(indexOfExistTab);
      return;
    }
    let name = order.newWarpId;
    if (order.shipmentCodes.split(',').length == 1 && order.shipmentCodes.split(',')[0].length > 0) {
      name = order.shipmentCodes.split(',')[0];
    }
    this.tabs.next([...this.tabs.getValue(), {
      name: name,
      closable: true,
      type: "order-detail",
      data: {
        orderId: order.orderId
      }
    }]);
    this.activeTabIndex.next(this.tabs.getValue().length - 1);
  }

  closeTabByIndex(index: number) {
    this.tabs.next(this.tabs.getValue().filter((_, i) => i !== index));
  }

  setActiveTab(index: number) {
    this.activeTabIndex.next(index);
  }

 
  public async getShipments({ keyword }: { keyword: string }) {
    if (!keyword) return [];
    // https://gw.wearewarp.com/api/v2/orders?skip=0&limit=10&search=1407821
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_ORDERS)}/?search=${keyword}`).toPromise();
    return result?.data?.list_data || []
  }

  public async getOrderDetail(orderId: string) {
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_ORDERS)}/${orderId}`).toPromise();
    return result?.data;
  }

  public async getAllChildrens(shipmentId: string) {
    const result = await this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipmentId}/all-childrens`).toPromise();
    return result?.data?.list_data || []
  }

}