import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehicleOption, VehicleType } from "@wearewarp/types/data-model"
import { VehicleTypeService } from '@services/vehicle.service';
// import _ from 'underscore'
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { VehicleTypeOption, VehicleTypeValue } from './interface';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: '[vehicle-selector]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class VehicleSelector {
    types: VehicleType[] = []
    vehicleType = null
    selectedVehicle: VehicleType | null = null;

    public vehicles: VehicleType[] = []
    public vehicleSelectOptions: VehicleTypeOption[] = []
    loading: boolean = false
    options: VehicleOption[] = []
    @Input() quoting: boolean = false
    @Input() mode: NzSelectModeType = 'default'
    @Input() withOptions: boolean = false
    @Input() isDisabled: boolean = false
    @Input() totalPallet: number = 0;
    @Input() totalWeight: number = 0;
    @Input() placeholder = 'Vehicle Type';
    @Output() valueChange = new EventEmitter<VehicleTypeValue>();


    _value = null
    get value() {
        return  this._value
    }
    @Input() set value(v) {
        if (this.mode == 'default') {
            this._value = v
            this.vehicleType = v?.code
            this.options = v?.options || []
            this.selectedVehicle = this.vehicleType ? this.vehicles.filter(it => it.code === this.vehicleType)[0] : null
        } else {
            this._value = (v ?? []);
            this.vehicleType = this._value;
        }
        
    }

    constructor(private vehicleTypeService: VehicleTypeService) {
        this.loadVehicles()
        // this.vehicleType = this.value?.code
        // this.options = this.value?.options || []
        // this.selectedVehicle = this.vehicleType ? this.vehicles.filter(it => it.code === this.vehicleType)[0] : null

        // console.log('Init', this.value, this.quoting, this.withOptions)
    }

    ngOnChanges(): void {
        if (this.mode == 'default') {
            this.vehicleType = this.value?.code
            this.options = this.value?.options || []
            this.selectedVehicle = this.vehicleType ? this.vehicles.filter(it => it.code === this.vehicleType)[0] : null
        } else {
            this.vehicleType = this.value;
        }
        
    }

    onValueChange(event) {
        if (this.mode == 'default') {
            this.selectedVehicle = this.vehicles.filter(it => it.code === this.vehicleType)[0]
            const selectableOptions = this.selectedVehicle?.options || []
            this._value = {
                code: this.vehicleType,
                name: this.selectedVehicle?.name,
                palletCapacity: this.selectedVehicle?.palletCapacity,
                capacity: this.selectedVehicle?.capacity,
                metadata: this.selectedVehicle?.metadata,
                options: this.options.filter(it => selectableOptions.indexOf(it) >= 0),
            }
            this.valueChange?.emit(this._value)
        } else {
            this.valueChange?.emit(event)
        }
        
    }

    onOptionChange(type, event) {
        if (event) {
            if (this.options.indexOf(type) < 0)
                this.options.push(type)
        } else {
            this.options = this.options.filter(it => it !== type)
        }
        const selectableOptions = this.selectedVehicle?.options || []
        this._value = {
            ...this._value,
            options: this.options.filter(it => selectableOptions.indexOf(it) >= 0),
        }
        this.valueChange?.emit(this._value)
    }

    loadVehicles() {
        this.loading = true
        this.vehicleTypeService.listAll(
            (data) => {
                this.loading = false
                this.types = data.filter(it => it.active !== false)
                let allTypes = {}
                for (let t of this.types) {
                    allTypes[t.code] = t
                }
                this.vehicles = this.types.filter(it => !!it.selectable).sort((v1, v2) => ((v1.order ?? 0) < (v2.order ?? 0) ? -1 : ((v1.order ?? 0) > (v2.order ?? 0) ? 1 : 0)));
                // this.vehicles = _.sortBy(this.types.filter(it => !!it.selectable), "order")
                this.vehicleSelectOptions = this.vehicles.filter(it => !this.quoting || it.quotable !== false).map<VehicleTypeOption>(t => {
                  const opt: VehicleTypeOption = {
                    label: t.name,
                    groupLabel: allTypes[t.parent]?.name,
                    value: t.code,
                    disabled: !t.selectable
                  }
                  return opt;
                });
                this.selectedVehicle = this.vehicleType ? this.vehicles.filter(it => it.code === this.vehicleType)[0] : null
            },
            () => {
                this.loading = false
                this.types = []
                this.vehicles = []
                this.vehicleSelectOptions = []
                this.selectedVehicle = null
            }
        )
    }

    get shouldShowWarning(): boolean {
        if(!this.selectedVehicle?.palletCapacity) return false;
        if(!this.selectedVehicle?.capacity?.weight) return false;
        return this.totalPallet > (this.selectedVehicle?.palletCapacity || 0) || this.totalWeight > (this.selectedVehicle?.capacity?.weight.value || 0);
    }

    public formatNumber(item: number) {
        return InputHelper._formatMoney(item, 0);
    }
}