<div *ngIf="isShipmentFinished(model)" class="top15">
  <nz-badge *ngIf="isShowConfirmPOD()" [nzCount]="getCountPodNotConfirmed()"
    [nzOverflowCount]="9">

    <a (click)="onOpenConfirmPod()">Confirm POD
      ({{countConfirmedPOD()}}/{{countPOD()}})</a>
    <!-- icon pod issue -->
    <span *ngIf="hasIssuePod()" style="padding-left: 5px;" nz-icon nzType="warning"
      class="f20 warning" nzTheme="outline" nz-tooltip nzTooltipTitle="PODs have issue"></span>

    <span *ngIf="isConfirmedPOD()" style="padding-left: 5px;" nz-icon nzType="check-circle"
      class="f16 success" nzTheme="outline" nz-tooltip nzTooltipTitle="PODs confirmed"></span>
    <!-- icon confirmed -->
    <div *ngIf="getLastUserConfirmPOD()" style="text-align: left; color: #666;" nz-tooltip
      nzTooltipTitle="Last confirmed by" nzTooltipPlacement="left">
      <span nz-icon nzType="user" style="padding-right: 3px;" class="f16" nzTheme="outline"></span>
      <span>{{getLastUserConfirmPOD()}}</span>
    </div>

  </nz-badge>
  <a *ngIf="!isShowConfirmPOD() && !isShipmentCanceled(model) && !isInvoiceWithoutPOD()"
    target="_blank" (click)="onOpenConfirmPod()">
    No POD yet
  </a>
  <div *ngIf="!isShowConfirmPOD()" class="top10"><a (click)="onOpenConfirmPod()">Generate invoice</a></div>
</div>