<div *ngIf="loading" class="loading-container">
  <div class="loading-icon">
      <i nz-icon nzType="loading" nzTheme="outline"></i>
  </div>
</div>

<div>
    <nz-select
      [nzMode]="mode"
      [(ngModel)]="vehicleType"
      (ngModelChange)="onValueChange($event)"
      [nzOptions]="vehicleSelectOptions"
      nzBackdrop="true"
      [nzDisabled]="isDisabled"
      style="width: 100%;"
      nzAllowClear [nzPlaceHolder]="placeholder"
      class="top-right vehicle-type">
    </nz-select>
</div>
<ng-container *ngIf="mode=='default'" >
  <div *ngIf="withOptions && selectedVehicle?.options?.length" class="vehicle-option">
    <label class="right10">With</label>
    <label *ngFor="let option of selectedVehicle?.options"
      nz-checkbox [nzDisabled]="isDisabled"
      [ngModel]="options.indexOf(option) >= 0"
      (ngModelChange)="onOptionChange(option, $event)"
    >
      <normalized-string [str]="option"></normalized-string>
    </label>
  </div>
</ng-container>

<div class="warning-message" *ngIf="shouldShowWarning">
  The <b>{{ selectedVehicle?.name }}</b> vehicle you selected has a maximum capacity of <b>{{ formatNumber(selectedVehicle?.capacity?.weight?.value || 0) }} lbs and {{ formatNumber(selectedVehicle?.palletCapacity || 0) }} pallets</b>. 
  However, this load contains <b>{{ formatNumber(totalWeight) }} lbs and {{ formatNumber(totalPallet) }} pallets</b>. 
  Please confirm your action carefully.
</div>