<div nz-col nzSpan="24">
  <div class="select-warehouse-container">
  </div>
</div>
<div class="group-filter">
  <div class="form-item">
    <div class="form-label-v2 single-line fix-height">Search Warehouse</div>
    <nz-select nzAllowClear nzShowSearch [(ngModel)]="warehouseId" [nzLoading]="isLoadingWarehouseList"
      [nzPlaceHolder]="'Please select a warehouse'" (ngModelChange)="onChangeWarehouse($event)" style="width: 300px;">
      <nz-option *ngFor="let item of crossdockWarehouseList" [nzValue]="item.id"
        [nzLabel]="item?.label || item?.name"></nz-option>
    </nz-select>
  </div>
  <div class="form-item">
    <div class="form-label-v2 single-line fix-height" [innerHTML]="'&nbsp'"></div>
    <button class="left10" nz-button (click)="onBtnRefresh()">
      <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
      <span *ngIf="!isLoading" nz-icon nzType="reload" nzTheme="outline"></span> Refresh
    </button>
  </div>
</div>

<nz-tabset [(nzSelectedIndex)]="selectedTabIndex" (nzSelectedIndexChange)="onTabChange($event)" nzSize="small">
  <ng-container *ngFor="let tab of tabs">
    <nz-tab [nzTitle]="tab.title">
      <div class="action-container">
        <ng-container>
          <div><span>Total </span><span class="text-count">{{summary.totalRecords}}</span></div>
        </ng-container>
      </div>
      <ng-template nz-tab>
        <nz-table style="padding: 0px 16px 16px; background: white;" nzBordered="true" #rowSelectionTable
          [nzScroll]="{ x: 'scroll' }" [nzTotal]="totalCount" [nzPageSize]="limit" [nzData]="listData"
          [nzHideOnSinglePage]="true" [nzFrontPagination]="false" [nzLoading]="isLoading"
          [nzLoadingIndicator]="tplLoading" [nzShowPagination]="totalCount > listData.length"
          (nzPageIndexChange)="onDataListPageChanged($event)" id="warehouse-shipments">
          <ng-template #tplLoading>
            <div class="nodata">
              <i nz-icon nzType="loading" nzTheme="outline"></i>
            </div>
          </ng-template>
          <thead>
            <tr>
              <th nzWidth="120px">Status</th>
              <th nzWidth="200px">Warehouse</th>
              <th nzWidth="150px">WARP Barcode</th>
              <th nzWidth="200px">External Barcodes</th>
              <th nzWidth="120px">Type</th>
              <th nzWidth="120px">Shipment</th>
              <th nzWidth="150px">Report Date</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of listData; trackBy: trackByRow">
              <tr style="cursor: pointer" warehouse-item-barcode-issues-list-item [item]="row"
                [warehouses]="crossdockWarehouseList" (click)="onExpandChange(row)" data-id="{{row.id}}"></tr>
              <tr [nzExpand]="expandSet.has(row.id)">
                <div *ngIf="expandSet.has(row.id)" warehouse-item-barcode-issues-item-content [item]="row"
                  (onRefresh)="onRefresh()" style="width: 100%;"></div>
              </tr>
            </ng-container>
          </tbody>
        </nz-table>
      </ng-template>
    </nz-tab>
  </ng-container>
</nz-tabset>