import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { ImageLocal } from "../quickUploadPhoto.interface";
import { QuickUploadPhotoService } from "../quickUploadPhoto.service";
import { PanZoomConfig, PanZoomAPI } from 'ngx-panzoom';
import { NzNotificationDataOptions } from "ng-zorro-antd/notification";

@Component({
  selector: "pod-image-preview",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class ImagePreview extends BaseComponent {
  public panZoomConfig: PanZoomConfig = new PanZoomConfig({
    // scalePerZoomLevel: 1
    freeMouseWheelFactor: 0.01
  });
  public selectedImage: ImageLocal;
 
  private panZoomAPI: PanZoomAPI;
  private observerResize: ResizeObserver;
  private isPanZoomReady: boolean = false;
  public viewImageFullScreen: boolean = false;

  constructor(private quickUploadPhotoService: QuickUploadPhotoService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.panZoomConfig.api.subscribe((api: PanZoomAPI) => {
      this.panZoomAPI = api;
      this.isPanZoomReady = true;
    }))
    this.subscription.add(this.quickUploadPhotoService.imageSelectedIndex.subscribe({
      next: index => {
        this.selectedImage = this.quickUploadPhotoService.getSelectedImage();
      }
    }));
  }
  ngAfterViewInit(): void {
    this.initResizeSensor()
  }

  initResizeSensor() {
    this.observerResize = new ResizeObserver(() => this.resetPanZoom())
    this.observerResize.observe(this.getContentDocument())
    this.observerResize.observe(document.querySelector(".image-preview"))
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyResizeSensor();
  }
  destroyResizeSensor() {
    this.observerResize?.unobserve(this.getContentDocument())
  }

  private getContentDocument() {
    return document.querySelector(".pod-panzoom .pan-zoom-frame .container-content");
  }

  resetPanZoom() {
    //check if panZoomAPI is available
    if (!this.isPanZoomReady) return;
    const contentWidth = this.getContentDocument()?.clientWidth;
    const contentHeight = this.getContentDocument()?.clientHeight;

    if (!contentWidth || !contentHeight) return;
    // this.panZoomAPI.detectContentDimensions()
    this.panZoomAPI.zoomToFit({ x: 0, y: 0, width: contentWidth, height: contentHeight });
    this.panZoomAPI?.resetView();
    this.panZoomAPI.centerX();

  }

  getExtractedData() {
    return this.selectedImage?.extractedData;
  }

  onSelectedWord(word: string) {
    this.quickUploadPhotoService.updateSearchText(word);
  }


  async onClickAction(action) {
    switch (action) {
      case 'fullscreen':
        this.viewFullScreen()
        break;
      case 'toggleGroup':
        if(this.selectedImage.groups?.length > 0) {
          this.quickUploadPhotoService.removeImageFromGroup(this.selectedImage);
          this.showSuccess(`Photo removed from group successfully!`, this.getNotificationOptions())
        }
        else {
          this.quickUploadPhotoService.addImageToGroup(this.selectedImage);
          this.showSuccess(`Photo added to group successfully!`, this.getNotificationOptions())
        }
        break;
      case 'download':
        this.download()
        break;
      case 'analyzePhoto':
        this.analyzePhoto()
        break;
      case 'remove':
        this.remove()
        break;
    }
  }

  //actions
  private viewFullScreen() {
    this.viewImageFullScreen = !this.viewImageFullScreen;
    setTimeout(() => {
    this.resetPanZoom()
    }, 10);
  }

  private async download() {
    await this.quickUploadPhotoService.downloadImage(this.selectedImage);
  }
  private async analyzePhoto() {
    await this.quickUploadPhotoService.extractTextFromImage(this.quickUploadPhotoService.getSelectedIndex());
  }

  private async remove() {
    this.confirmDeletion({
      message: "Are you sure you want to remove this photo?",
      fnOk: () => {
        this.quickUploadPhotoService.removeImage(this.selectedImage);
        this.showSuccess(`Photo deleted successfully!`, this.getNotificationOptions())
      }
    })
  }

  getNotificationOptions(): NzNotificationDataOptions {
    let options: NzNotificationDataOptions = {
      nzPlacement: 'bottomLeft',
      nzDuration: 1500,
      nzStyle: {
        padding: '12px'
      }
    }
    return options;
  }
}
