<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Route settings</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- cancel button with confirmation -->
        <button (nzOnConfirm)="onBtnCancel()"
                *ngIf="isEditing && isInputsChange()"
                [disabled]="onProgress"
                class="btn-primary right10"
                nz-button
                nz-popconfirm
                nzPopconfirmPlacement="top"
                nzOkText="Yes"
                nzCancelText="No"
                nzPopconfirmTitle="Are you sure you want to cancel editing?">
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
          <!-- cancel button -->
        <button (click)="onBtnCancel()"
                *ngIf="isEditing && !isInputsChange()"
                [disabled]="onProgress"
                class="btn-primary right10"
                nz-button>
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- save button -->
        <button
          nz-button
          nzType="primary"
          (click)="onBtnSave()"
          class="btn-primary"
          *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate"
          [nzLoading]="onProgress"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content warp-info">
      <div class="tdm-row cell-spacing">
        <div class="tdm-col tdm-col-3" *ngFor="let key of ['startRouteHours']">
          <div>
            <div class="form-label-v2">
              {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <nz-input-group>
                  <input
                    nz-input
                    [formControlName]="key"
                    [type]="getInputType(key)"
                    [placeholder]="getPlaceHolder(key)"
                    (input)="onInputChanged($event, key)"
                    (keypress)="onInputKeyPress($event, key)"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</form>
