import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input } from '@angular/core';
import { Const } from '@const/Const';
import { environment } from '@env/environment';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { Carrier, Job } from '@wearewarp/types/data-model';
import { Const as WarpConst } from '@wearewarp/universal-libs';

@Component({
  selector: 'routes-for-shipments',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../list.scss'],
})
export class RoutesForShipments extends BaseComponent{
  @Input() shipmentIds: string[] = [];
  @Input() listJobs: Job[] = [];
  @Input() clientId: string = '';
  total = 0;
  isLoading = false;
  pageSize = 3;
  listCarriers: Carrier[] = [];
  get totalCount() {
    return this.listJobs.length;
  }

  ngOnChanges(): void {
    this.loadCarrier();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  
  constructor() {
    super();
  }

  loadCarrier() {
    let jobsHaveCarrier = this.listJobs.filter(job => job.assignedCarrier?.carrierId);
    const url = jobsHaveCarrier.map(job => Const.APIV2(`${Const.APIURI_CARRIERS}/${job.assignedCarrier?.carrierId}/get-detail-simple` ));
    this.api.concurrentGET(url).subscribe((res) => {
      this.listCarriers = res.map(it => it.data).filter(carrier => !!carrier);
    })
  }

  getJobId(job: any): string {
    return job.id || job.jobId;
  }

  getJobType(job: Job) {
    if (this.isTonuRoute(job)) return "TONU";
    return "Normal";
  }

  getJobStatus(job: Job) {
    return job.status || WarpConst.JobStatus.created;
  }

  getCarrierId(job: Job) {
    return job.assignedCarrier?.carrierId;
  }

  getJobTotalCost(job: Job) {
    return InputHelper.formatMoney2(`${job?.assignedCarrier?.cost?.grandTotal || 0}`);
  }

  getCarrierNameFromJob(job: Job) {
    let carrier = this.listCarriers.find(carrier => carrier.id == this.getCarrierId(job));
    if (carrier) return this.getCarrierName(carrier);
    return "";
  }

  getCarrierMCDot(job: Job) {
    let carrier = this.listCarriers.find(carrier => carrier.id == this.getCarrierId(job));
    if (!carrier) return "";
    let mc = `MC: ${carrier.basicInfo?.mc ? carrier.basicInfo?.mc: "N/A"}`;
    let dot = `DOT: ${carrier.basicInfo?.dot ? carrier.basicInfo?.dot : "N/A"}`;
    return `${mc} / ${dot}`;
  }

  isTonuRoute(job: Job) {
    let isTonu = true;
    const tasks = job.tasks || [];
    const pickupTasks = tasks.filter(t => t.type == Const.TaskType.PICKUP);

    if (!pickupTasks.length) return false;
    if (pickupTasks.length) {
      pickupTasks.forEach((task) => {
        if (task.status != Const.TaskStatus.canceled || !task.tonu) isTonu = false;
      })
    }
    
    return isTonu;
  }

  copyRouteTrackingLink(job: Job) {
    let url = `${environment.trackingWebUrl}/${job?.code}/${this.clientId}`;
    Utils.copyTextToClipboard(url, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('Copy successful');
      }
    })
  }

}