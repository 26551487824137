<div class="flex">
    <nz-spin [nzSpinning]="false">
      <label nz-popover 
        [nzPopoverVisible]="isShowPopoverReason" 
        [nzPopoverTrigger]="null" 
        [nzPopoverContent]="doNotInvoiceReason"
        [nzPopoverOverlayStyle]="{ width: '400px' }"
        nz-checkbox 
        (ngModelChange)="onOpenPopoverReason($event)" 
        [(ngModel)]="doNotInvoiceData.isDoNotInvoice">
        Do not invoice
      </label>
    </nz-spin>
    <span *ngIf="doNotInvoiceData.isDoNotInvoice && !isShowPopoverReason" class="grey reason-text">{{displayReason()}}</span>
    <ng-container *ngIf="showHistory">
      <span *ngIf="doNotInvoiceData.isDoNotInvoice && !isShowPopoverReason && historyData?.whenBy" 
        class="left5 grey reason-text">
        by {{getFullName(historyData?.whenBy?.byUser)}} at {{displayDateDB(historyData?.whenBy?.when)}}
      </span>
    </ng-container>
  </div>
  
  <ng-template #doNotInvoiceReason>
    <div class="popover-content">
      <div class="bottom10 medium">
        Reason for Do not invoice<span class="label-mark-required"></span>
      </div>
      
      <nz-select 
        style="width: 100%"
        [(ngModel)]="doNotInvoiceData.reasonType"
        (ngModelChange)="onReasonTypeChange($event)"
        nzPlaceHolder="Select reason">
        <nz-option
          *ngFor="let option of reasonOptions"
          [nzValue]="option.value"
          [nzLabel]="option.label">
        </nz-option>
      </nz-select>

      <div *ngIf="isOtherReason()" class="top15">
        <input 
          nz-input 
          type="text"
          placeholder="Enter other reason" 
          [(ngModel)]="doNotInvoiceData.reason"/>
      </div>

      <div class="flex top20" style="justify-content: space-between">
        <button nz-button (click)="onClosePopoverReason()">Cancel</button>
        <button 
          nz-button 
          nzType="primary"
          [disabled]="!isAllowSave()"
          (click)="onSavePopoverReason()">Save</button>
      </div>
    </div>
  </ng-template>