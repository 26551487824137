import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { ModelOrderDetail } from '@app/interfaces/order';
import _ from "underscore";
import { ConfirmPod } from "@app/admin/shipments/confirm-pod";
import { DialogService } from '@dialogs/dialog.service';
import { Const } from "@const/Const";

@Component({
  selector: 'confirm-pod-info',
  templateUrl: './confirm-pod-info.component.html',
  styleUrls: ['./confirm-pod-info.component.scss']
})

export class ConfirmPodInfoComponent extends BaseComponent {
  @Input() clientInvoiceWithoutPods: any[] = [];
  @Output() onConfirmPOD = new EventEmitter();
  
  private _model: ModelOrderDetail;
  get model(): ModelOrderDetail {
    return this._model;
  }
  @Input() set model(value: ModelOrderDetail) {
    this._model = value;
  }

  onOpenConfirmPod() {
    const isInvoiceWithoutPOD = this.isInvoiceWithoutPOD();
    let afterClose = new EventEmitter();
    afterClose.subscribe(() => {
      this.onConfirmPOD.emit(this.model);
    })
    DialogService.openDialog(ConfirmPod, {
      nzComponentParams: {
        data: { isInvoiceWithoutPOD, orderId: this.model?.id, status: this.model?.status },
        onDone: invoice => {
          this.model.invoice = invoice;
          this.model.invoiceFileId = invoice._id;
          this.onConfirmPOD.emit(this.model);
        }
      },
      nzClosable: false,
      nzClassName: 'modal-no-padding modal-fullscreen',
      nzCentered: true,
      nzAfterClose: afterClose,
    });
  }

  countPOD() {
    const shipments = this.model?.metadata?.shipments || [];
    let count = 0;
    for (let shipment of shipments) {
      count += shipment?.pod?.total ?? 0;
    }
    return count;
  }

  countConfirmedPOD() {
    const shipments = this.model?.metadata?.shipments || [];
    let countNotConfirmed = 0;
    for (let shipment of shipments) {
      countNotConfirmed += shipment?.pod?.notConfirmed ?? 0;
    }
    return this.countPOD() - countNotConfirmed;
  }

  // Các trường hợp cần hiển thị button "confirm POD"
  // - Có ít nhất 1 POD chưa confirm.
  // - Có ít nhất 1 shipment chưa có POD => bật dialog lên thì sẽ nhìn thấy shipment nào thiếu POD
  isShowConfirmPOD() {
    return this.countPOD() > 0;
  }

  isShipmentCanceled(item): boolean {
    return this.checkShipmentStatus(item, [Const.OrderStatus.canceled])
  }

  isShipmentFinished(item) {
    return this.checkShipmentStatus(item, [Const.OrderStatus.complete, Const.OrderStatus.disposed, Const.OrderStatus.returned, Const.OrderStatus.canceled, Const.OrderStatus.pickupFailed, Const.OrderStatus.removed])
  }

  isOrder(item) {
    if (item.shipmentIds) return true;
    return false;
  }

  hasIssuePod() {
    const shipments = this.model?.metadata?.shipments || [];
    let hasIssue = false;
    for (let shipment of shipments) {
      const pod = shipment.pod || {}
      if (pod?.totalPodHasIssue && pod?.notConfirmed) {
        hasIssue = true;
        break;
      }
    }
    return hasIssue;
  }

  isConfirmedPOD() {
    const shipments = this.model?.metadata?.shipments || [];
    let confirmed = true;
    for (let shipment of shipments) {
      const pod = shipment.pod || {}
      if (pod?.notConfirmed) {
        confirmed = false;
        break;
      }
    }
    return confirmed;
  }

  checkShipmentStatus(item, status = []): boolean {
    if (!this.isOrder(item)) {
      if (item?.status && status.includes(item.status)) {
        return true;
      }
    } else {
      const childrens = item?.metadata?.shipments || [];
      const check = childrens.map(children => this.checkShipmentStatus(children, status));
      return check.filter(status => status).length == childrens.length
    }
    return false;
  }

  isInvoiceWithoutPOD() {
    const clientIds = this.model?.clientIds || [];
    let result = this.clientInvoiceWithoutPods.find(it => clientIds.includes(it));
    return result ? true : false
  }

  public getCountPodNotConfirmed() {
    // return item.podInfo.countPodNotConfirmed;
    return 0;
  }

  getLastUserConfirmPOD() {
    const shipments = this.model?.metadata?.shipments || [];
    let lastConfirmedPod: any = {};
    for (let shipment of shipments) {
      const tasks = shipment?.pod?.tasks || [];
      for (let task of tasks) {
        if (!task?.confirmed) continue;
        const pods = task.podArr || [];
        for (let pod of pods) {
          if (!pod?.confirmed) continue;
          const podConfirmed = pod.podConfirmed || {};
          if (!lastConfirmedPod?.when || lastConfirmedPod?.when < podConfirmed?.when) {
            lastConfirmedPod = pod;
          }
        }
      }
    }
    return this.getFullName(lastConfirmedPod?.podConfirmed?.byUser) || '';
  }
  
}