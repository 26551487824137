import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MasterData } from '@services/master.data';

export interface DoNotInvoiceData {
  isDoNotInvoice: boolean;
  reason: string;
  reasonType?: string;
}
@Component({
  selector: 'do-not-invoice',
  templateUrl: './do-not-invoice.component.html',
  styleUrls: ['./do-not-invoice.component.scss']
})

export class DoNotInvoiceComponent extends BaseComponent {
  private static readonly DEFAULT_DO_NOT_INVOICE: DoNotInvoiceData = {
    isDoNotInvoice: false,
    reason: '',
    reasonType: ''
  } as const;

  @Input() doNotInvoiceData: DoNotInvoiceData = { ...DoNotInvoiceComponent.DEFAULT_DO_NOT_INVOICE };
  @Input() showHistory = false;
  @Input() historyData: any;

  @Output() doNotInvoiceChange = new EventEmitter<DoNotInvoiceData>();

  isShowPopoverReason = false;

  readonly reasonOptions = MasterData.getDoNotInvoiceReasons();

  public onOpenPopoverReason(checked: boolean): void {
    this.isShowPopoverReason = checked;
    if (!checked) {
      this.resetAndEmit();
    }
  }

  public onClosePopoverReason(): void {
    this.isShowPopoverReason = false;
    this.resetData();
  }

  public onSavePopoverReason(): void {
    this.isShowPopoverReason = false;
    if (!this.hasValidReason) {
      this.doNotInvoiceData.isDoNotInvoice = false;
    } else if (this.doNotInvoiceData.isDoNotInvoice) {
      this.emitChange();
    }
  }

  private resetData(): void {
    this.doNotInvoiceData = { ...DoNotInvoiceComponent.DEFAULT_DO_NOT_INVOICE };
  }

  private resetAndEmit(): void {
    this.resetData();
    this.emitChange();
  }

  public displayReason(): string {
    return this.doNotInvoiceData.reason ? `(${this.doNotInvoiceData.reason})` : '';
  }

  private emitChange() {
    this.doNotInvoiceChange.emit(this.doNotInvoiceData);
  }

  public isAllowSave(): boolean {
    return this.hasValidReason;
  }

  public isOtherReason(): boolean {
    return this.doNotInvoiceData.reasonType === 'OTHER';
  }

  public onReasonTypeChange(value: string): void {
    this.doNotInvoiceData.reasonType = value;
    if (!this.isOtherReason()) {
      const selectedOption = this.reasonOptions.find(opt => opt.value === value);
      this.doNotInvoiceData.reason = selectedOption?.label || '';
    } else {
      this.doNotInvoiceData.reason = '';
    }
  }

  private get hasValidReason(): boolean {
    if (!this.doNotInvoiceData.reasonType) return false;
    if (this.isOtherReason()) {
      return !!this.doNotInvoiceData.reason?.trim();
    }
    return true;
  }
}