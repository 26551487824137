import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Order, Shipment, DeliveryInfo, WarehouseTask, ReportItemIssue, DataPalletUnrecognized, IssueTicketStatus } from "@wearewarp/types/data-model";
import { NzNotificationDataOptions } from "ng-zorro-antd/notification";
import { environment } from "@env/environment";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import { ItemIssueService } from "../../ItemIssue.service";
import { QuickUploadPhotoService } from "@app/admin/components/quick-upload-photos/quickUploadPhoto.service";
import { TaskType } from "@wearewarp/types";
import { OrderUtil } from "@services/order";
import { DateUtil } from "@services/date-utils";
import { ShipmentItem } from "@wearewarp/types/data-model/types/ShipmentItem";
import { Const as WarpConst } from "@wearewarp/universal-libs";
interface ItemWithTasks extends ShipmentItem {
  tasks: WarehouseTask[];
}
@Component({
  selector: "shipment-detail-form",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class ShipmentDetailForm extends BaseComponent {
  constructor(
    private itemIssueService: ItemIssueService,
    private quickUploadPhotoService: QuickUploadPhotoService
  ) {
    super();
    this.dataorch = new DataorchService(this.api);
  }

  @Input() shipment: Shipment;
  @Input() showHeaderBox: boolean = true;
  dataorch: DataorchService;
  public childrens: Shipment[] = [];
  public selectedShipment: Shipment;
  public isAssignPalletInProcessing: boolean = false;
  public isShowActualTime: boolean = true;
  public items: any[] = [];
  public tasks: WarehouseTask[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
  }

  async initData() {
    await this.getAllChildrens();
  }

  loadItems() {
    console.log("loadItems", this.selectedShipment);
    if (!this.selectedShipment.id) return
    //sử dụng cho môi trường dev, không có server dataorch
    if (!environment.dataorchUrl) {
      console.warn('environment.dataorchUrl is not defined. Using shipment.items instead of dataorch.listShipmentItems()')
      this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${this.selectedShipment.id}/items`).subscribe(async (res) => {
        this.items = await this.loadQuantityItems(res.data?.list_data || [])
      });
      return;
    }

    this.dataorch.listShipmentItems(this.selectedShipment.id).subscribe(async (res) => {
      this.items = await this.loadQuantityItems(res || []);
    })
    
  }

  loadQuantityItems(items: ShipmentItem[]): ItemWithTasks[] {
    let quantityItems = [];
    for (let item of items) {
      const quantityItem = item.quantityItems || [];
      quantityItems.push(...quantityItem);
    }
    quantityItems.map(item => {
      const tasks = this.getItemTasks(item);
      item.tasks = tasks;
      return item;
    })
    console.log("quantityItems done");
    return quantityItems;
  }

  getItemTasks(item: ItemWithTasks) {
    // const dropoff = this.selectedShipment.deliveryInfos.find(deliveryInfo => deliveryInfo.type === Const.TaskType.DROPOFF);
    // this.api.GET(`${Const.APIURI_WAREHOUSE_ITEM}/${item.id}`, {
    //   customHeaders: {
    //     'Warehouseid': dropoff.warehouseId
    //   }
    // }).subscribe((res) => {
    //   let tasks: WarehouseTask[] = res.data?.warehouseTasks || [];
    //   tasks = tasks.filter(task => task.stage == Const.WarehouseTaskStage.inbound);
    //   item.tasks = [...item.tasks, ...tasks];
    //   return item;
    // });
    const tasks = this.tasks.filter(task => task.itemId == item.id);
    return tasks;
  }

  getShipmentTask() {
    const tasks = this.tasks.filter(task => task.level == WarpConst.WarehouseTaskLevel.shipment);
    return tasks;
  }

  async loadTasks() {
    const res = await this.api.GET(`${Const.APIURI_WAREHOUSE_SHIPMENT}/${this.selectedShipment.id}/tasks?stage=${Const.WarehouseTaskStage.inbound}`, {
      customHeaders: {
        'Warehouseid': this.selectedShipment.deliveryInfos.find(deliveryInfo => deliveryInfo.type === Const.TaskType.DROPOFF).warehouseId
      }
    }).toPromise()
    console.log("loadtask done", res);
    this.tasks = res.data?.list_data || [];
  }

  async getAllChildrens() {
    const shipmentId = this.shipment?.id;
    if (this.shipment.isCrossDock) {
      this.childrens = await this.itemIssueService.getAllChildrens(shipmentId);
      if (this.childrens.length > 0) {
        // this.selectedShipment = JSON.parse(JSON.stringify(this.childrens[0]));
        return;
      }
    }
    // this.selectedShipment = JSON.parse(JSON.stringify(this.shipment));
  }

  //params: shipment._id
  async onSelectShipment(shipmentId) {
    if (shipmentId == this.shipment._id) {
      this.selectedShipment = this.shipment;
      return;
    }
    this.selectedShipment = this.childrens.find(shipment => shipment._id == shipmentId);
    await this.loadTasks();
    this.loadItems();
    console.log("selectedShipment", this.selectedShipment);
  }


  getNotificationOptions(): NzNotificationDataOptions {
    let options: NzNotificationDataOptions = {
      nzPlacement: 'bottomLeft',
      nzDuration: 1500,
      nzStyle: {
        padding: '12px'
      }
    }
    return options;
  }

  getDeliveryInfo(deliveryInfos: DeliveryInfo[], type: TaskType) {
    const deliveryInfo = deliveryInfos.find(deliveryInfo => deliveryInfo.type === type);
    const locationName = deliveryInfo?.locationName || '';
    const address = this.getAddressText(deliveryInfo.addr);
    const scheduleTime = this.getDeliveryInfoDate(deliveryInfo);
    const refNums = deliveryInfo?.refNums?.join(', ') || '';
    return { locationName, address, scheduleTime, refNums };
  }

  public getDeliveryInfoDate(deliveryInfo: DeliveryInfo) {
    const formatDateTime = 'MM/DD/YYYY, h:mm a';
    const formatDate = 'MM/DD/YYYY';
    let timezone = deliveryInfo.addr?.metadata?.timeZoneStandard;
    let timeWindow = OrderUtil.getTimeWindowForDeliveryInfo(deliveryInfo);
    if (!timeWindow) return 'N/A';
    return DateUtil.displayTimeWindow(timeWindow, { timezone, format: formatDateTime, formatDateOnly: formatDate });
  }

  getTaskColor(status: string) {
    switch (status) {
      case Const.WarehouseTaskStatus.pending:
        return 'orange';
      case Const.WarehouseTaskStatus.completed:
        return 'green';
      case Const.WarehouseTaskStatus.skipped:
        return 'red';
    }
  }
  getTaskName(task: WarehouseTask) {
    switch (task.type) {
      case Const.WarehouseTaskType.scanPallet:
        return 'Scan Pallet';
      case Const.WarehouseTaskType.assignBarcode:
        return 'Assign Barcode';
      case Const.WarehouseTaskType.addDimensions:
        return 'Add Dimensions';
      case Const.WarehouseTaskType.addWeight:
        return 'Add Weight';
      case Const.WarehouseTaskType.markLoaded:
        return 'Mark Loaded';
      case Const.WarehouseTaskType.printLabel:
        return 'Print Label';
      case Const.WarehouseTaskType.uploadBOL:
        return 'Upload BOL';
      case Const.WarehouseTaskType.uploadProductPhoto:
        return 'Upload Photo';

    }
  }

  async linkWithPallet(item: ItemWithTasks) {
    this.confirmYesNo(`Are you sure you want to link item with this pallet?`, async () => {
      this.isAssignPalletInProcessing = true;
      try {
        console.log("selectedShipment", this.selectedShipment);
        console.log("item", item);
        const ticket: ReportItemIssue = this.itemIssueService.getSelectedIssueTicket();
        const ticketData: DataPalletUnrecognized = ticket.data as DataPalletUnrecognized;

        //làm các task của item
        await this.updateItemTask(item, ticketData);

        //làm các task shipment
        await this.updateShipmentTask(this.selectedShipment, ticketData);

        //update status ticket
        await this.updateItemIssue({
          id: ticket.id,
          status: WarpConst.IssueTicketStatus.CLOSED as IssueTicketStatus,
          data: {
            shipmentId: this.selectedShipment.id,
            itemId: item.id
          } as unknown as DataPalletUnrecognized
        });

        this.loadItems();
        //update status ticket on view
        ticket.status = WarpConst.IssueTicketStatus.CLOSED as IssueTicketStatus;

        this.showSuccess(`Pallet has been added successfully to shipment ${this.showShipmentWarpId(this.selectedShipment.warpId)}`, this.getNotificationOptions());
        this.isAssignPalletInProcessing = false;

      } catch (err) {
        this.showErr(err.message, this.getNotificationOptions());
        this.isAssignPalletInProcessing = false;
      }
    });
  }

  private async updateItemTask(item: ItemWithTasks, ticketData: DataPalletUnrecognized) {
    await Promise.all(item.tasks.map(task => {
      if (task.status == Const.WarehouseTaskStatus.completed) return;
      if (task.type === Const.WarehouseTaskType.assignBarcode) {
        return this.confirmBarcode({ taskId: task.id, itemId: item.id, barcode: ticketData.warpBarcodes?.[0] });
      }
      if (task.type === Const.WarehouseTaskType.addWeight) {
        return this.confirmWeight({ taskId: task.id, itemId: item.id, weight: ticketData.weight });
      }
      if (task.type === Const.WarehouseTaskType.uploadProductPhoto) {
        return this.assignPhotos({
          taskId: task.id, itemId: item.id, uploadIds: [
            ...ticketData.itemPhotoIds,
            ...ticketData.bolPhotoIds,
            ...ticketData.barcodePhotoIds
          ]
        });
      }
      if (task.type === Const.WarehouseTaskType.scanPallet) {
        return this.scanPallet({ taskId: task.id, itemId: item.id, barcode: ticketData.barcodes[0] });
      }
    }));
  }

  private async updateShipmentTask(shipment: Shipment, ticketData: DataPalletUnrecognized) {
    const shipmentTasks = this.getShipmentTask();
    await Promise.all(shipmentTasks.map(task => {
      if (task.type == Const.WarehouseTaskType.uploadBOL) {
        return this.addBOL({ taskId: task.id, shipmentId: shipment.id, uploadIds: [...ticketData.bolPhotoIds] });
      }
    }));
  }

  private async assignPhotos({ taskId, itemId, uploadIds }) {
    if (!uploadIds.length) return;
    const url = `${Const.APIURI_WAREHOUSE_TASK}/${taskId}`;
    const data = {
      itemId, uploadIds
    };
    return this.api.PUT(url, data).toPromise()
  }

  private async confirmWeight({ taskId, itemId, weight }) {
    if (!weight) return;
    const url = `${Const.APIURI_WAREHOUSE_TASK}/${taskId}`;
    return this.api.PUT(url, { itemId, weight }).toPromise()
  }

  private async confirmBarcode({ taskId, itemId, barcode }) {
    if (!barcode) return;
    const url = `${Const.APIURI_WAREHOUSE_TASK}/${taskId}`;
    return this.api.PUT(url, { itemId, barcode }).toPromise()
  }

  private async scanPallet({ taskId, itemId, barcode }) {
    if (!barcode) return;
    const url = `${Const.APIURI_WAREHOUSE_TASK}/${taskId}`;
    return this.api.PUT(url, { itemId, barcode }).toPromise()
  }

  private async addBOL({ taskId, shipmentId, uploadIds }) {
    if (!uploadIds.length) return;
    const url = `${Const.APIURI_WAREHOUSE_TASK}/${taskId}`;
    return this.api.PUT(url, { shipmentId, uploadIds }).toPromise()
  }

  private async updateItemIssue(itemIssue: Partial<ReportItemIssue>) {
    const url = `${Const.API_SERVICE_WAREHOUSE}/admin/issues/${itemIssue.id}`;
    return this.api.PUT(url, itemIssue).toPromise()
  }

  urlToShipment() {
    return `${Const.routeAdminOrderList}/${this.shipment.orderId}`;
  }
}
