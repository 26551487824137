<div *ngIf="!additionalCost" nz-checkbox [(ngModel)]="isInvoiceSent"
  [nzDisabled]="!canToggleInvoiceSent()" (ngModelChange)="onBtnInvoiceSent($event)"></div>

<div *ngIf="additionalCost" nz-checkbox [(ngModel)]="additionalCost.isInvoiceSent"
  [nzDisabled]="!canToggleInvoiceAdditinalSent()"
  (ngModelChange)="onBtnAdditionalInvoiceSent($event)"></div>

<ng-template #tplNotifyCreateQBInvoice let-data="data">
  <div *ngIf="data.mode == 'error'">
    <span nz-icon nzType="info-circle" nzTheme="outline" style="color: #CD4246; font-size: 16px;"></span>
    <span style="font-weight: 500; font-size: 16px; margin-left: 5px;">Quickbooks invoice creation failed</span>
  </div>
  <div *ngIf="data.mode == 'success'">
    <span nz-icon nzType="info-circle" nzTheme="outline" style="color: #238552; font-size: 16px;"></span>
    <span style="font-weight: 500; font-size: 16px; margin-left: 5px;">Quickbooks invoice creation successful</span>
  </div>
  <div *ngIf="data.message">
    <div [innerHTML]="data.message"></div>
  </div>
</ng-template>