import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzListModule } from "ng-zorro-antd/list";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzImageModule } from 'ng-zorro-antd/image';
import { SearchBoxModule } from "@libs/search-box/search-box.module";
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ClientList } from "./list";
import { ClientDetail } from "./detail";
import { DetailModule } from "../base/detail.module";
import { ListLocation } from "./list-location";
import { WareHouseModule } from "../warehouses/module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { FormAddressUSModule } from "../base/form-address-us/module";
import { FinanceModule } from "../finance/module";
import { SharedModule } from "../shared/shared.module";
import { AddQuickOrderSetting } from "./quick-order-settings";
import { ClientDetailBasicInfo } from "./detail/components/basic-info";
import { ClientDetailContact } from "./detail/components/contact";
import { ClientDetailPayment } from "./detail/components/payment";
import { ClientAdd } from "./add";
import { ClientDetailNotificationRecipient } from "./detail/components/notification-recipient";
import { ClientDetailLabelTemplate } from "./detail/components/label-template";
import { ClientDetailAssignedInformation } from "./detail/components/assigned-info";
import { ClientDetailCrossDockInfo } from "./detail/components/crossdock-info";
import { ClientDetailQuickOrderSetting } from "./detail/components/quick-order-setting";
import { ClientParentCompanyInfo } from '@app/admin/clients/detail/components/parent-company-info';
import { ClientSubAccountManagement } from '@app/admin/clients/detail/components/sub-account-management';
import { ClientParentAccountManagement } from '@app/admin/clients/detail/components/parent-account-management';
import { LocationSetting } from "./location-setting";
import { ClientDetailLocationSetting } from "./detail/components/location-setting";
import { ClientDetailLogo } from "./detail/components/logo";
import { ImageCropperModule } from 'ngx-image-cropper';
import { ClientDetailAutoReportNotification } from "./detail/components/auto-report";
import { AutoMessagesSetting } from "./auto-message-setting";
import { ModuleFinStatement } from "../fin/components/fin-statement/module";
import { ModuleCustomerDepositAmount } from "./detail/components/deposit-amount/module";
import { ClientFilter } from "./client-filter";
import { ClientRouteSetting } from "./detail/components/route-setting";
import { ModuleWhenByForList } from "../components/whenby-for-list/module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzListModule,
    NzSwitchModule,
    NzSpaceModule,
    NzRadioModule,
    NzDatePickerModule,
    NzAutocompleteModule,
    NzTimePickerModule,
    NzCheckboxModule,
    NzImageModule,
    NzCollapseModule,
    SearchBoxModule,
    DetailModule,
    FormAddressUSModule,
    WareHouseModule,
    SelectBySearchingModule,
    FinanceModule,
    SharedModule,
    ImageCropperModule,
    ModuleFinStatement,
    ModuleWhenByForList,
    ModuleCustomerDepositAmount,
  ],
  declarations: [
    ClientList,
    ListLocation,
    AddQuickOrderSetting,
    ClientAdd,
    ClientDetailBasicInfo,
    ClientDetailContact,
    ClientDetailPayment,
    ClientDetailNotificationRecipient,
    ClientDetailLabelTemplate,
    ClientDetailAssignedInformation,
    ClientDetailCrossDockInfo,
    ClientDetailQuickOrderSetting,
    ClientParentAccountManagement,
    ClientSubAccountManagement,
    ClientParentCompanyInfo,
    ClientDetail,
    ClientDetailLocationSetting,
    LocationSetting,
    ClientDetailLogo,
    ClientDetailAutoReportNotification,
    AutoMessagesSetting,
    ClientFilter,
    ClientRouteSetting,
  ],
  exports: [ClientList, ClientDetail, ListLocation, ClientAdd],
  providers: [],
})
export class ClientModule { }
