import {Component, Input} from '@angular/core';
import { Const } from "@const/Const";
import { FormControl } from "@angular/forms";
import { InputHelper } from "@services/input-helper";
import { BaseFormItem } from "@app/admin/base/form-item";

@Component({
  selector: "[client-detail-assigned-information]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// WARP Assigned Information
export class ClientDetailAssignedInformation extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    saleUserId: { label: "Assigned Seller" }, // label cũ Assigned Sales Rep => Assigned National Account Director
    customerServiceUserId: { label: "Account Owner" }, // label cũ Assigned Client Success Rep => Assigned Client Success Manager
    customerServiceRepUserId: { label: "Assigned Customer Service Rep" },
    creditLimit: { label: "Credit Limit", getValue: InputHelper.getValueMoney, formatValue: InputHelper.formatMoney },
    creditRemaining: { label: "Credit Remaining", readOnly: true, formatValue: InputHelper.formatMoney },
    payableAvgDays: { label: "Payables AVG Days Outstanding", readOnly: true },
    freightQuoteEnabled: { label: "Freight Quote Functionality", type: "boolean", initialValue: false },
    hideWarpBrandInfo: {label: 'Hide WARP brand info', type: 'boolean', initialValue: false},
    isCreditLimitExceeded: {label: 'Credit Exceeded', type: 'boolean', initialValue: false, readOnly: !this.requirePermissions([this.PermissionCode.creditExceeded.edit])},
    isShowBusinessNumber: {label: 'Business Number (EDI)', type: 'boolean', initialValue: false},
    enableCarrierInReport: {label: 'Enable Carrier Column in Report', type: 'boolean', initialValue: false},
    isNotRequireLT: {label: 'Do not require Load Tender Acceptance', type: 'boolean', initialValue: false},
    needBuildTaskLayover: {label: 'Need to build task for Layover', type: 'boolean', initialValue: false},
    isShowLoadFunctions: {label: 'Load Functionality', type: 'boolean', initialValue: false},
    isShowCarrierNameInQuote: { label: "Display Carrier Name in Freight Quote", type: "boolean", initialValue: false },
    isShowQuoteSourceName:  { label: "Display Source Name in Freight Quote", type: "boolean", initialValue: false },
    isUseCrossDock: { label: "Use Crossdock for all LTL shipments", type: "boolean", initialValue: false },
    isInvoiceWithoutPOD: { label: 'Invoice Without POD', type: 'boolean', initialValue: false },
    isShowCarrierNameInMarketPlaceQuote: { label: 'Display Carrier Name in Marketplace Quote', type: 'boolean', initialValue: false },
    isOnlyShowWARPLTLQuoteInFreightQuote: { label: "Only show WARP's quote in LTL Freight Quote", type: 'boolean', initialValue: false },
    canUseFtlBooking: { label: 'FTL Freight Quote', type: 'boolean', initialValue: false },
    canEditItemOrderFTL: { label: 'Edit item details for FTL orders', type: 'boolean', initialValue: false },
    marketplaceLTLEnabled: { label: "Marketplace LTL Functionality", type: "boolean", initialValue: false },
    canUploadLogo: { label: "Upload Custom Logo", type: "boolean", initialValue: false },
    classificationSettings: {
      label: "",
      type: "formGroup",
      notAcceptEmpty: true,
      childItem: {
        productType: { label: "Product Type", notAcceptEmpty: true },
        classificationShipment: { label: "Shipment Classification", notAcceptEmpty: true },
      },
    },
    isShowDriverGPSMapOnTrackingPage: { label: "Show Driver GPS Map on Tracking Page", type: "boolean", initialValue: false },
    isShowCustomerNameInAppointment: { label: "Show customer name at appointment page/email", type: "boolean", initialValue: false },
  };

  public allSaleUsers = [];
  public productTypeArr = Object.keys(Const.ProductTypes);
  public classificationShipmentArr = Object.keys(Const.ClassificationShipments);

  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
    this.getAllSales();
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  public isLoadingSales = false;

  private getAllSales() {
    this.isLoadingSales = true;
    this.api.GET(`${Const.APIURI_USERS}?saleOnly=1`).subscribe(
      (resp) => {
        this.isLoadingSales = false;
        this.allSaleUsers = resp.data.list_data;
      },
      (err) => {
        this.isLoadingSales = false;
        this.showErr(err);
      }
    );
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  getDataChildModelToArray(key) {
    return this.formInput.get(key) ? this.formInput.get(key).value : [];
  }

  onInputChanged(event, key) {
    switch (key) {
      case "creditLimit":
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event: KeyboardEvent, key): boolean {
    switch (key) {
      case "creditLimit":
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

}
