<div nz-col nzSpan="24">
  <div class="select-warehouse-container">
  </div>
</div>
<div class="group-filter">
  <div class="form-item">
    <div class="form-label-v2 single-line fix-height">Warehouse</div>
    <nz-select
      nzAllowClear
      nzShowSearch
      [(ngModel)]="warehouseId"
      [nzLoading]="isLoadingWarehouseList"
      nzAllowClear="false"
      [nzPlaceHolder]="'Please select a warehouse'"
      (ngModelChange)="onChangeWarehouse($event)"
      style="width: 250px;">
      <nz-option
        *ngFor="let item of crossdockWarehouseList"
        [nzValue]="item.id"
        [nzLabel]="item?.label || item?.name"
      ></nz-option>
    </nz-select>
  </div>
  <div warehouse-shipment-filter></div>
  <div class="form-item">
    <div class="form-label-v2 single-line fix-height" [innerHTML]="'&nbsp'"></div>
    <button nz-button (click)="onBtnFilter('drawer')"
      [nzType]="hasFilter ? 'primary' : 'default'">
      <i nz-icon nzType="filter" nzTheme="outline"></i>Advanced Filter
    </button>
    <button class="left10" nz-button (click)="onBtnRefresh()">
      <i *ngIf="isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
      <span *ngIf="!isLoading" nz-icon nzType="reload" nzTheme="outline"></span> Refresh
    </button>
    <button class="left10" nz-button (click)="onBtnExportPallets()">
      <i *ngIf="isLoadingExport" nz-icon nzType="loading" nzTheme="outline"></i>
      <span *ngIf="!isLoadingExport" nz-icon nzType="export" nzTheme="outline"></span> Export Pallets
    </button>
  </div>
</div>
<div class="tab-summary-container"*ngIf="getSelectedItems().length && warehouseId">
  <ng-container *ngTemplateOutlet="tplTab"></ng-container>
</div>
<div *ngIf="!getSelectedItems().length && warehouseId" class="tab-summary-container">
  <ng-container *ngTemplateOutlet="tplTab"></ng-container>
</div>

<div class="action-container">
  <ng-container *ngIf="getSelectedItems().length" >
    <div class="flex">
      <div>
        <span>Total </span><span class="text-count">{{summary.totalRecords}}</span><span> | </span><span class="text-count">{{getSelectedItems().length}} Selected</span>
      </div>
      <div class="text-unselect" (click)="onBtnClearAllSelected()">Unselect all</div>
      <button (click)="exportExcel()" nz-button nzType="default">
        Export Manifest ({{getSelectedItems().length}})
      </button>
      <button (click)="onBtnDownloadBols()" nz-button nzType="default">
        Export Bols
        <span *ngIf="loadingBol" nz-icon nzType="loading"></span>
      </button>  
      <button
        nz-button
        nzTrigger="hover"
        nz-dropdown
        [nzDropdownMenu]="menuRoute">
        More actions <span nz-icon nzType="down" nzTheme="outline"></span>
        <nz-dropdown-menu #menuRoute="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item (click)="onBtnManualRouteInbound()">
              <span>Create Inbound Route </span>
            </li>
            <li nz-menu-item (click)="onBtnManualRouteOutbound()">
              <span> Create Outbound Route</span>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item (click)="onBtnCopyInboundIds()">
              <span style="margin-right: 3px" nz-icon nzType="copy" nzTheme="outline"></span> Copy Inbound Leg Ids
            </li>
            <li nz-menu-item (click)="onBtnCopyOutboundIds()">
              <span style="margin-right: 3px" nz-icon nzType="copy" nzTheme="outline"></span> Copy Outbound Leg Ids
            </li>
            <li nz-menu-item (click)="onBtnCopyMainIds()">
              <span style="margin-right: 3px" nz-icon nzType="copy" nzTheme="outline"></span> Copy Main Shipment Ids
            </li>
            <li nz-menu-item (click)="onBtnCopyOrderIds()">
              <span style="margin-right: 3px" nz-icon nzType="copy" nzTheme="outline"></span> Copy Order Ids
            </li>
            <li nz-menu-divider></li>
            <ng-container *ngFor="let item of actionsUpdateScheduleTime">
              <li nz-menu-item (click)="item?.action()">
                <span>
                  <span style="margin-right: 3px" nz-icon nzType="edit" nzTheme="outline"></span>
                  {{ item?.label }}
                </span>
              </li>
            </ng-container>
          </ul>
        </nz-dropdown-menu>
      </button>
    </div>
    <div><span>Total pallet </span><span class="text-count">{{summary.pallets}}</span></div>
  </ng-container>
  <ng-container *ngIf="!getSelectedItems().length" >
    <div><span>Total </span><span class="text-count">{{summary.totalRecords}}</span></div>
    <div><span>Total pallet </span><span class="text-count">{{summary.pallets}}</span></div>
  </ng-container>
</div>

<ng-template #tplTab>
  <div warehouse-shipment-filter-tab [currentFilter]="currentFilter" [options]="options" (onTabChanged)="onBtnClearAllSelected()"></div>
</ng-template>

<nz-table style="padding: 0px 12px; background: white;"
  nzBordered="true"
  #rowSelectionTable
  [nzScroll]="{ x: 'scroll' }"
  [nzTotal]="totalCount"
  [nzPageSize]="limit"
  [nzData]="listData"
  [nzHideOnSinglePage]="true"
  [nzFrontPagination]="false"
  [nzLoading]="isLoading"
  [nzLoadingIndicator]="tplLoading"
  [nzShowPagination]="totalCount > listData.length"
  [nzScroll]="{ x: 'scroll', y: '550px' }"
  (nzPageIndexChange)="onDataListPageChanged($event)"
  id="warehouse-shipments">
  <ng-template #tplLoading>
    <div class="nodata">
      <i nz-icon nzType="loading" nzTheme="outline"></i>
    </div>
  </ng-template>
  <thead>
    <tr>
      <th
        nzWidth="45px"
        [nzChecked]="checkedAll"
        [nzIndeterminate]="indeterminate"
        (nzCheckedChange)="onAllChecked($event)"
      ></th>
      <th nzWidth="100px">Status</th>
      <th nzWidth="75px">Tasks</th>
      <th nzWidth="140px">Shipment</th>
      <th nzWidth="200px">Client Name</th>
      <th nzWidth="200px">PO#</th>
      <th nzWidth="130px">Inventories</th>
      <th nzWidth="130px">Weight</th>
      <th nzWidth="140px">Inbound Leg</th>
      <th nzWidth="140px">Outbound Leg</th>
      <th nzWidth="170px">Schedule Pickup</th>
      <th nzWidth="170px">Schedule Inbound</th>
      <th nzWidth="170px">Schedule Outbound</th>
      <th nzWidth="170px">Schedule Delivery</th>
      <th nzWidth="300px">Pickup Location</th>
      <th nzWidth="300px">Delivery Location</th>
      <th nzWidth="300px">Original Pickup Location</th>
      <th nzWidth="300px">Final Delivery Location</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let row of listData">
      <tr
        style="cursor: pointer"
        warehouse-item-list-item
        [item]="row"
        (onItemSelected)="onItemChecked(row)"
        [selected]="!!setOfCheckedId[row.id]"
        (click)="onExpandChange(row)"
        data-id="{{row.id}}"
      ></tr>
      <tr [nzExpand]="expandSet.has(row.id)">
        <div *ngIf="expandSet.has(row.id)" warehouse-item-content [item]="row" (onRefresh)="onRefresh()"></div>
      </tr>
    </ng-container>
  </tbody>
</nz-table>
