<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">WARP Assigned Information</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- cancel button with confirmation -->
        <button (nzOnConfirm)="onBtnCancel()"
                *ngIf="isEditing && isInputsChange()"
                [disabled]="onProgress"
                class="btn-primary right10"
                nz-button
                nz-popconfirm
                nzPopconfirmPlacement="top"
                nzOkText="Yes"
                nzCancelText="No"
                nzPopconfirmTitle="Are you sure you want to cancel editing?">
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
          <!-- cancel button -->
        <button (click)="onBtnCancel()"
                *ngIf="isEditing && !isInputsChange()"
                [disabled]="onProgress"
                class="btn-primary right10"
                nz-button>
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- save button -->
        <button
          nz-button
          nzType="primary"
          (click)="onBtnSave()"
          class="btn-primary"
          *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate"
          [nzLoading]="onProgress"
        >
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content warp-info">
      <div class="tdm-row cell-spacing">
        <div
          class="tdm-col tdm-col-3"
          *ngFor="let key of ['saleUserId', 'customerServiceUserId', 'customerServiceRepUserId']"
        >
          <div>
            <div class="form-label-v2">
              {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <nz-select
                  nzBackdrop="true"
                  style="width: 100%"
                  nzShowSearch
                  nzAllowClear
                  [nzLoading]="isLoadingSales"
                  [nzPlaceHolder]="getPlaceHolder(key)"
                  [formControlName]="key"
                >
                  <nz-option
                    *ngFor="let user of allSaleUsers"
                    [nzLabel]="getFullName(user)"
                    [nzValue]="user?.id"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div class="tdm-row cell-spacing">
        <div class="tdm-col tdm-col-3" *ngFor="let key of ['creditLimit', 'creditRemaining', 'payableAvgDays']">
          <div>
            <div class="form-label-v2">
              {{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <nz-input-group [nzPrefix]="key == 'payableAvgDays' ? null : '$'">
                  <input
                    nz-input
                    [formControlName]="key"
                    [type]="getInputType(key)"
                    [placeholder]="getPlaceHolder(key)"
                    (input)="onInputChanged($event, key)"
                    (keypress)="onInputKeyPress($event, key)"
                  />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <div class="tdm-row cell-spacing">
        <div class="tdm-col tdm-col-3" *ngFor="let key of ['isShowLoadFunctions', 'freightQuoteEnabled', 'canUseFtlBooking', 'hideWarpBrandInfo']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="tdm-col tdm-col-3" *ngFor="let key of ['isOnlyShowWARPLTLQuoteInFreightQuote']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="tdm-col tdm-col-3" *ngFor="let key of ['isShowCarrierNameInQuote']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="tdm-col tdm-col-3" *ngFor="let key of ['isShowQuoteSourceName']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        
        <div class="tdm-col tdm-col-3" *ngFor="let key of ['isUseCrossDock']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="tdm-col tdm-col-3" *ngFor="let key of ['isInvoiceWithoutPOD']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="tdm-col tdm-col-3" *ngFor="let key of ['isShowCarrierNameInMarketPlaceQuote']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="tdm-col tdm-col-3" *ngFor="let key of ['marketplaceLTLEnabled']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="tdm-col tdm-col-3" *ngFor="let key of ['canUploadLogo']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <ng-container *ngIf="isAccountingManager">
          <div class="tdm-col tdm-col-3" *ngFor="let key of ['isCreditLimitExceeded']">
            <div>
              <nz-form-item>
                <nz-form-control>
                  <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isAccountingManager">
          <div class="tdm-col tdm-col-3" *ngFor="let key of ['isShowBusinessNumber']">
            <div>
              <nz-form-item>
                <nz-form-control>
                  <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </ng-container>

        <div class="tdm-col tdm-col-3" *ngFor="let key of ['enableCarrierInReport']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="tdm-col tdm-col-3" *ngFor="let key of ['isNotRequireLT']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div class="tdm-col tdm-col-3" *ngFor="let key of ['needBuildTaskLayover', 'isShowDriverGPSMapOnTrackingPage', 'isShowCustomerNameInAppointment', 'canEditItemOrderFTL']">
          <div>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [formControlName]="key">{{getLabel(key)}}</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let groupKey of ['classificationSettings']">
        <form nz-form [formGroupName]="groupKey">
          <div *ngFor="let key of ['productType']" class="flex bottom10">
            <div>{{getLabel(fullKey(groupKey,key))}}:</div>
            <div class="left10">
              <nz-radio-group [formControlName]="key">
                <label *ngFor="let item of productTypeArr" nz-radio [nzValue]="item">{{item}}</label>
              </nz-radio-group>
            </div>
          </div>
          <div *ngFor="let key of ['classificationShipment']" class="flex">
            <div>{{getLabel(fullKey(groupKey,key))}}:</div>
            <div class="left10">
              <nz-radio-group [formControlName]="key">
                <label *ngFor="let item of classificationShipmentArr" nz-radio [nzValue]="item">{{item}}</label>
              </nz-radio-group>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</form>
