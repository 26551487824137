import { Component, Input } from '@angular/core';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { InputHelper } from '@services/input-helper';

@Component({
  selector: 'view-wave-detail',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ViewWaveDetailComponent extends BaseFormDialog1 {
  @Input() waveId: string;
  @Input() objectId: string;
  @Input() mode: string = 'bid';
  @Input() smsSent: number = 0;
  @Input() emailSent: number = 0;
  @Input() time: string;

  public isLoading = false;
  public listData: any[] = [];
  public totalCount: number = 0;
  public limit: number = Const.PAGINATION_LIMIT;
  public pageIndex: number = 1;
  public searchText: string = '';

  constructor(api: ApiService) {
    super();
    this.api = api;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData()
  }

  get cost(): string {
    return this.formatMoney(this.smsSent * 0.017);
  }

  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }

  initData() {
    const skip = this.pageIndex ? (this.pageIndex - 1) * this.limit : 0;
    this.isLoading = true;
    const baseUrl = this.mode === 'bid' ? Const.APIV2(Const.APIURI_CARRIER_BIDS) : Const.APIURI_BID_SESSIONS;
    const url = `${baseUrl}/${this.objectId}/waves/${this.waveId}/candidates`;
    let params = '';
    if (this.searchText) {
      params += `search=${this.searchText}&`;
    }
    params += `skip=${skip}&limit=${this.limit}`;

    this.api.GET(`${url}?${params}`).subscribe(
      (response) => {
        this.listData = response.data?.list_data || [];
        this.totalCount = response.data?.total || 0;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  onDataListPageChanged(pageIndex: number) {
    this.pageIndex = pageIndex;
    this.initData();
  }

  onSearchTextChanged(event) {
    this.pageIndex = 1;
    this.initData();
  }

  $asSentStatusColor = (status = "") => {
    switch (status?.toLowerCase()) {
      case 'pending':
      case 'notsent':
        return 'gray';
      case 'sent':
      case 'success':
      case 'unknown':
        return 'green';
      case 'failed':
      case 'invalid':
      case 'canceled':
      case 'unsubscribed':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
        return 'orange';
      default:
        return '';
    }
  }

  $asSentStatusText = (status = "") => {
    switch (status?.toLowerCase()) {
      case 'notsent':
      case 'pending':
        return 'Pending';
      case 'canceled':
        return 'Cancelled';
      case 'sent':
      case 'unknown':
        return 'Sent';
      case 'failed':
        return 'Failed';
      case 'success':
        return 'Success';
      case 'delivered':
        return 'Delivered';
      case 'opened':
        return 'Opened';
      case 'clicked':
        return 'Clicked';
      case 'unsubscribed':
        return 'Unsubscribed';
      case 'undelivered':
        return 'Undelivered';
      case 'invalid':
        return 'Invalid';
      case 'unreachable':
        return 'Unreachable';
      default:
        return status;
    }
  }
}