<div *ngIf="showHeaderBox" class="header-box">
  <div class="selected-shipment">
    <div class="shipmentId">Selected: <b>{{showShipmentCode(selectedShipment)}}</b></div>
    <div class="actions">
       </div>
  </div>
</div>
<div class="shipment-network" *ngIf="shipment" shipment-structure [shipment]="shipment" [children]="childrens"
  [refId]="'struture'+shipment.id" [presentedShipment]="selectedShipment" [items]="items" [viewOnly]="true"
  [highlights]="[selectedShipment?.id]" [isShowActualTime]="isShowActualTime" (onSelectShipment)="onSelectShipment($event)"></div>

<div class="shipment-detail" *ngIf="selectedShipment">
  <div class="shipment-detail-header">
    <b>Shipment: 
      <a [href]="urlToShipment()" target="_blank">{{showShipmentCode(selectedShipment)}}</a>
    </b>
  </div>
  <nz-table [nzData]="[selectedShipment]" [nzShowPagination]="false">
    <thead>
      <tr>
        <th>PICKUP: {{getDeliveryInfo(selectedShipment.deliveryInfos, "PICKUP")?.locationName || ''}}</th>
        <th>DROPOFF: {{getDeliveryInfo(selectedShipment.deliveryInfos, "DROPOFF")?.locationName || ''}}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div class="location-info">
            <div class="location-address">{{getDeliveryInfo(selectedShipment.deliveryInfos, "PICKUP")?.address}}</div>
            <div class="schedule-time"><b>Schedule:</b> {{getDeliveryInfo(selectedShipment.deliveryInfos, "PICKUP")?.scheduleTime}}</div>
            <div class="ref"><b>Ref:</b> {{getDeliveryInfo(selectedShipment.deliveryInfos, "PICKUP")?.refNums}}</div>
          </div>
        </td>
        <td>
          <div class="location-info">
            <div class="location-address">{{getDeliveryInfo(selectedShipment.deliveryInfos, "DROPOFF")?.address}}</div>
            <div class="schedule-time"><b>Schedule:</b> {{getDeliveryInfo(selectedShipment.deliveryInfos, "DROPOFF")?.scheduleTime}}</div>
            <div class="ref"><b>Ref:</b> {{getDeliveryInfo(selectedShipment.deliveryInfos, "DROPOFF")?.refNums}}</div>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div> 
<div class="item-detail" *ngIf="selectedShipment">
  <div class="item-detail-header">
    <b>Items</b>
  </div>
  <nz-table [nzData]="[items]" [nzShowPagination]="false">
    <thead>
      <tr>
        <th>ID</th>
        <th>WARP Barcode</th>
        <th>Barcodes</th>
        <th>Info</th>
        <th>Warehouse Task</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items">
        <td>{{item.warpId || 'N/A'}}</td>
        <td>{{item.barcode || 'N/A'}}</td>
        <td>
          <nz-tag *ngFor="let barcode of item.barcodes" [nzColor]="'default'">{{barcode}}</nz-tag>
        </td>
        <td>
          {{item.name || 'N/A'}}
          <br>
          {{item.length}} x {{item.width}} x {{item.height}} {{item.sizeUnit}}
          <br>
          {{item.actualWeight || item.weightPerUnit}} {{item.actualUnit || item.weightUnit}}
        </td>
        <td>
          <nz-tag *ngFor="let task of item.tasks" [nzColor]="getTaskColor(task.status)">{{getTaskName(task)}}</nz-tag>
        </td>
        <td>
          <button nz-button nzType="link" (click)="linkWithPallet(item)">Link with pallet</button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>