import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { OrderDetailTabContext } from "@app/admin/components/quick-upload-photos/quickUploadPhoto.interface";
import { Order, Shipment } from "@wearewarp/types/data-model";
import { ItemIssueService } from "../../ItemIssue.service";

@Component({
  selector: "order-detail-form",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class OrderDetailForm extends BaseComponent {
  constructor(private itemIssueService: ItemIssueService) {
    super();
  }
  @Input() data: OrderDetailTabContext;
  public order: Order;
  public shipments: Shipment[] = [];

  ngOnInit(): void {
    super.ngOnInit();
    // this.subscription.add(this.quickUploadPodService.imageSelectedIndex.subscribe({
    //   next: index => {
    //     this.selectedImage = this.quickUploadPodService.getSelectedImage();
    //   }
    // }));
    this.initData();
  }

  async initData() {
    await this.getOrder();
  }

  async getOrder() {
    const orderId = this.data?.orderId;
    this.order = await this.itemIssueService.getOrderDetail(orderId);
    this.shipments = <Shipment[]>this.order?.metadata?.shipments || [];
  }


}
