import {Component, Input} from '@angular/core';
import { Const } from "@const/Const";
import { FormControl } from "@angular/forms";
import { InputHelper } from "@services/input-helper";
import { BaseFormItem } from "@app/admin/base/form-item";

@Component({
  selector: "[client-detail-route-settings]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Route Settings
export class ClientRouteSetting extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    startRouteHours: {label: 'Time to start route before PU (hours)', type: 'number'},
  };

  public allSaleUsers = [];
  public productTypeArr = Object.keys(Const.ProductTypes);
  public classificationShipmentArr = Object.keys(Const.ClassificationShipments);

  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  public isLoadingSales = false;

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  getDataChildModelToArray(key) {
    return this.formInput.get(key) ? this.formInput.get(key).value : [];
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'startRouteHours': return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event: KeyboardEvent, key): boolean {
    switch (key) {
      case 'startRouteHours': return InputHelper.handleInputKeyPressNumberOnly(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

}
