import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';

import { QuickUploadPhotoService } from '@app/admin/components/quick-upload-photos/quickUploadPhoto.service';
import { DataPalletUnrecognized, ReportItemIssue } from '@wearewarp/types/data-model';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { FileUtil } from '@services/file-util';
import { Const } from '@const/Const';
import { ImageLocal } from '@app/admin/components/quick-upload-photos/quickUploadPhoto.interface';
import { ItemIssueService } from '../ItemIssue.service';

@Component({
    selector: '[warehouse-item-barcode-issues-item-content]',
    templateUrl: './view.html',
    styleUrls: ['./style.scss']
})
export class WarehouseItemBarcodeIssuesItemContent extends BaseComponent {
    _item: ReportItemIssue;
    public loadingImages: boolean = false;
    private isImageInitialized: boolean = false;
    private isDestroyed: boolean = false;
    @ViewChild('containerBox') containerBox: ElementRef;
    private images: {
        blob: Blob,
        name: string,
        url: string,
        fileId: string
    }[] = [];

    @Input() set item(value) {
        this._item = value;
        this.itemIssueService.setSelectedIssueTicket(value);
        if (!this.isImageInitialized) {
            this.initImage();
            this.isImageInitialized = true;
        }
        console.log(value)
    }

    constructor(
        private itemIssueService: ItemIssueService,
        private quickUploadPhotoService: QuickUploadPhotoService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.initRemoveImage();
        this.setContainerHeight();
    }

    ngAfterViewInit() {
        // Đợi DOM được render xong để có thể lấy được offsetTop chính xác
        setTimeout(() => {
            this.setContainerHeight();
        });
    }

    private setContainerHeight() {
        if (this.containerBox) {
            const containerElement = this.containerBox.nativeElement;
            const rect = containerElement.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const newHeight = windowHeight - 150; // Giữ khoảng cách 50px từ top
            console.log('Rect:', rect);
            console.log('Window height:', windowHeight);
            console.log('New height:', newHeight);
            containerElement.style.height = `${newHeight}px`;

            // Scroll window đến vị trí của container box
            window.scrollTo({
                top: window.scrollY + rect.top - 50, // 50px là khoảng cách từ top của window
                behavior: 'smooth'
            });
        }
    }

    ngOnDestroy() {
        this.isDestroyed = true;
        this.quickUploadPhotoService.clearImages();
    }

    async initRemoveImage() {
        this.subscription.add(this.quickUploadPhotoService.removeImageIndex.subscribe({
            next: index => {
                this.removeImage(index);
            }
        }));
    }

    async initImage() {
        console.log("initImage", this.loadingImages)
        if (this.loadingImages || this.isDestroyed) return;
        this.loadingImages = true;
        const data = this._item?.data;
        const issueType = this._item?.type;
        const palletImages = (data as DataPalletUnrecognized)?.itemPhotoIds || [];
        const bolImages = (data as DataPalletUnrecognized)?.bolPhotoIds || [];
        const barcodeImages = (data as DataPalletUnrecognized)?.barcodePhotoIds || [];
        const images = [...palletImages, ...bolImages, ...barcodeImages];
        if (images.length) {
            //download image
            await Promise.all(images.map(async imageId => {
                if (this.isDestroyed) return;
                const buffer: ArrayBuffer = await this.downloadImage(imageId);
                if (this.isDestroyed) return;
                const blob = new Blob([buffer]);
                const url = URL.createObjectURL(blob);
                const name = `Ticket-${this._item.id}-${Math.random()}.png`;
                const existingImage = this.images.find(image => image.fileId === imageId);
                if (!existingImage && !this.isDestroyed) {
                    this.images.push({
                        blob,
                        name,
                        url,
                        fileId: imageId
                    })

                    this.quickUploadPhotoService.addImage({
                        blob,
                        name,
                        url
                    });
                }
            }));
            if (!this.isDestroyed) {
                this.cdr.detectChanges();
            }
        }

        this.loadingImages = false;
    }

    private removeImage(index: number) {
        console.log("removeImage", index)
        const image = this.images[index];
        console.log("image", image);
        //call api remove image
    }

    private async downloadImage(fileId: string) {
        let url = `${Const.APIURI_DOWNLOAD}/${fileId}`;
        return this.api.download(url).toPromise();
    }
}