import { Component, Input } from '@angular/core';
import { StopItem } from './models/types';
import { NetworkDataService } from './services/network-data.service';

@Component({
    selector: '[truck-load-evolution]',
    templateUrl: './index.html',
    styleUrls: ['./style.scss']
})
export class TruckLoadEvolutionComponent {
    @Input() set jobId(value: string) {
        this._jobId = value;
        this.fetchData();
    }
    @Input() perPage: number = 6;

    get jobId() {
        return this._jobId;
    }

    private _jobId: string;
    public isLoading: boolean = false;
    public stops: StopItem[] = [];
    public peakInfo: StopItem | null = null;
    public peakIndex: number = -1;
    public maxWeight: number = 0;
    public maxQuantity: number = 0;

    constructor(private networkDataService: NetworkDataService) {}

    private fetchData() {
        this.isLoading = true;
        this.networkDataService.fetchJobData(this.jobId).subscribe(
            ({ stops, maxWeight, maxQuantity }) => {
                this.isLoading = false;
                this.stops = stops;
                this.maxWeight = maxWeight || 0;
                this.maxQuantity = maxQuantity || 0;
                this.peakInfo = stops.reduce((max, stop) => 
                    (!max || stop.weight > max.weight) ? stop : max, null);
                this.peakIndex = stops.findIndex(stop => stop.id === this.peakInfo?.id);
            },
            err => {
                this.isLoading = false;
                console.error('Error fetching job data:', err);
            }
        );
    }
}