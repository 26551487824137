<div class="location-settings">
  <!-- Photo Setting -->
  <div class="photo-required-setting">
    <div class="setting-title">Photo Setting</div>
    <div class="row-item">
      <nz-switch nzSize="small" [(ngModel)]="photoRequired"></nz-switch> Photo of goods Required
      <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Photo of goods on the truck (for pickup) or at the delivery location." class="tooltip-icon"></i>
    </div>
    <div class="row-item">
      <nz-switch nzSize="small" [(ngModel)]="bolRequired"></nz-switch> BOL Required
      <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Bill of Lading (BOL) photo of document is required." class="tooltip-icon"></i>
    </div>
    <div class="row-item">
      <nz-switch nzSize="small" [(ngModel)]="signatureRequired"></nz-switch> Signature Required
      <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Shipper's or Consignee's signature is required at the location." class="tooltip-icon"></i>
    </div>

    <!-- Photo Upload Level -->
    <div class="row-item">
      <div>Photo Upload Level</div>
      <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Select if the photo should be uploaded for each shipment or only for stop." class="tooltip-icon"></i>  
      <nz-radio-group [(ngModel)]="podAtLocationLevel" class="radio-group">
        <label class="radio-item" nz-radio [nzValue]="false" [nzDisabled]="disableShipmentLevel">Shipment Level</label>
        <label class="radio-item" nz-radio [nzValue]="true">
          Stop Level
          <ng-container *ngIf="scanBarcodeRequired">
            <br>
            <span class="note">(Required if Scan Barcode is enabled)</span>
          </ng-container>
        </label>
      </nz-radio-group>
    </div>
  </div>

  <!-- Scan Barcode Setting -->
  <div class="barcode-setting">
    <div class="row-item parent-setting">
      <nz-switch nzSize="small" [(ngModel)]="scanBarcodeRequired"></nz-switch> Scan Barcode Required
      <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Enable this option if scanning barcodes is mandatory." class="tooltip-icon"></i>
    </div>
    <div class="child-settings">
      <div class="row-item">
        <nz-switch nzSize="small" [(ngModel)]="isAllowSearchBarcode" [disabled]="!scanBarcodeRequired"></nz-switch> Allow Search Barcode
        <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Allow driver to search barcode manually." class="tooltip-icon"></i>
      </div>
      <div class="row-item">
        <nz-switch nzSize="small" [(ngModel)]="isAllowPickupMoreItems" [disabled]="!scanBarcodeRequired"></nz-switch> Allow driver to add items
        <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Allow driver to add more items to the list." class="tooltip-icon"></i>
      </div>
      <div class="child-row-item">
        <nz-switch nzSize="small" [(ngModel)]="noExpectedBarcode" [disabled]="!isAllowPickupMoreItems"></nz-switch> Auto-add after one failed scan
        <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="If a barcode scan fails once, the scanned barcode will be automatically added to the system" class="tooltip-icon"></i>
      </div>
      <div class="row-item">
        <nz-switch nzSize="small" [(ngModel)]="isAllowPickupLessItems" [disabled]="!scanBarcodeRequired"></nz-switch> Allow driver to go if a barcode is not scanned
        <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Driver can continue without scanning all barcodes successfully." class="tooltip-icon"></i>
      </div>
    </div>
  </div>

  <!-- Geofencing feature with tooltip beside title -->
  <div class="geofencing-setting">
    <div class="setting-title">
      Geofencing feature
      <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Enable geofencing to restrict driver actions based on location." class="tooltip-icon"></i>
    </div>
    <div class="row-item">
      <nz-radio-group [(ngModel)]="useGeoFencing" class="radio-group">
        <label class="radio-item" nz-radio [nzValue]="">
          No
          <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="No restriction; driver actions are not location-based." class="tooltip-icon"></i>
        </label>
        <label class="radio-item" nz-radio [nzValue]="useGeoFencingType.force">
          Force
          <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Requires the driver to be at the stop location to mark as arrived." class="tooltip-icon"></i>
        </label>
        <label class="radio-item" nz-radio [nzValue]="useGeoFencingType.alert">
          Alert
          <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Warns the driver if not at the stop but allows arrival marking." class="tooltip-icon"></i>
        </label>
      </nz-radio-group>
    </div>
  </div>

  <!-- ID Verification -->
  <div class="row-item">
    <nz-switch nzSize="small" [(ngModel)]="isIdVerificationRequired"></nz-switch> ID Verification
    <i nz-icon nz-tooltip nzType="question-circle" nzTheme="outline" nz-tooltip="Requires verification of the consignee's ID card at delivery stop for alcohol products." class="tooltip-icon"></i>
  </div>
</div>