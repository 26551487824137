import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { SearchBoxModule } from "@libs/search-box/search-box.module";
import { DetailModule } from "../base/detail.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { FormAddressUSModule } from "../base/form-address-us/module";
import { NzImageModule } from "ng-zorro-antd/image";
import { WarehouseItemListItem } from "./shipments/item";
import { WarehouseItemContent } from "./shipments/item-content";
import { ViewShipmentBol } from "./shipments/bols";
import { NzModalModule } from "ng-zorro-antd/modal";
import { ShipRecModule } from "../base/shiprec/module";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { WarehouseShipment } from "./shipments";
import { WarehouseShipmentFilter } from "./shipments/filter";
import { WarehouseShipmentFilterExtend } from "./shipments/filter-extend";
import { WarehouseShipmentItem } from "./shipments/shipment-item";
import { TransitNetworkModule } from "../transit-network/module";
import { NzTagModule } from "ng-zorro-antd/tag";
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { DlgCreateRoute } from "./shipments/dlg-create-route";
import { DlgChecklistItems } from "./shipments/dlg-checklist-items";
import { DlgEditStorageTime } from "./shipments/dlg-edit-storage-time";
import { ModuleFilterLayout } from '@app/admin/components/filter-layout/module';
import { ModuleInputWarpIds } from '@wearewarp/ng-antd';
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { WarehouseRatePlanList } from '@app/admin/crossdock/rate-configuration/plan-list';
import { RatePlanFilter } from '@app/admin/crossdock/rate-configuration/filter';
import { AddPlan } from '@app/admin/crossdock/rate-configuration/add-plan';
import { SharedModule } from '@app/admin/shared/shared.module';
import { EditPlanDuration } from '@app/admin/crossdock/rate-configuration/edit-plan-duration';
import { ClonePlan } from '@app/admin/crossdock/rate-configuration/clone-plan';
import { WarehouseRatePlanDetail } from '@app/admin/crossdock/rate-configuration/plan-detail';
import { AddRateAdjustment } from '@app/admin/crossdock/rate-configuration/add-rate';
import { EditRateAdjustment } from '@app/admin/crossdock/rate-configuration/edit-rate';
import { WarehouseRateAdjustmentStorageFee } from '@app/admin/crossdock/rate-configuration/storage-fee';
import { WarehouseShipmentFilterTab } from "./shipments/filter-tab";
import { WarehouseExternalRouteConnecting } from "./external-route-connecting";
import { CreateExternalRouteConnecting } from "./external-route-connecting/create-external-route-connecting";
import { WarehouseTranfer } from "./external-route-connecting/warehouse-transfer";
import { WarehouseStorageFee } from '@app/admin/crossdock/rate-configuration/warehouse-storage-fee';
import { PalletHistoryModule } from "../components/pallet-history/module";
import { WarehouseItemBarcodeIssuesItemContent } from "./item-barcode-issues/item-content";
import { WarehouseItemBarcodeIssuesListItem } from "./item-barcode-issues/item";
import { WarehouseItemBarcodeIssues } from "./item-barcode-issues";
import { ModuleWhenByForList } from '@app/admin/components/whenby-for-list/module';
import { QuickUploadPhotosModule } from "@app/admin/components/quick-upload-photos/module";
import { ItemIssueService } from "./item-barcode-issues/ItemIssue.service";
import { TabFormInfo } from "./item-barcode-issues/search/tab-info";
import { SearchForm } from "./item-barcode-issues/search/search-form";
import { ShipmentDetailForm } from "./item-barcode-issues/search/shipment-detail-form";
import { OrderDetailForm } from "./item-barcode-issues/search/order-detail-form";
import { ShipmentStructureModule } from "../shipments/shipment-structure/module";
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzTabsModule,
    NzDividerModule,
    NzEmptyModule,
    NzDropDownModule,
    NzResultModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    SearchBoxModule,
    DetailModule,
    SelectBySearchingModule,
    FormAddressUSModule,
    NzModalModule,
    ShipRecModule,
    NzSpinModule,
    NzModalModule,
    NzDrawerModule,
    TransitNetworkModule,
    NzTagModule,
    PdfViewerModule,
    NzImageModule,
    NzToolTipModule,
    NzTabsModule,
    ModuleFilterLayout,
    ModuleInputWarpIds,
    NzTypographyModule,
    NzIconModule,
    SharedModule,
    ModuleWhenByForList,
    PalletHistoryModule,
    QuickUploadPhotosModule,
    ShipmentStructureModule
  ],
  declarations: [
    WarehouseShipment,
    WarehouseShipmentFilter,
    WarehouseShipmentFilterExtend,
    WarehouseShipmentFilterTab,
    WarehouseItemListItem,
    WarehouseItemContent,
    WarehouseShipmentItem,
    ViewShipmentBol,
    DlgCreateRoute,
    DlgChecklistItems,
    DlgEditStorageTime,
    WarehouseRatePlanList,
    RatePlanFilter,
    AddPlan,
    EditPlanDuration,
    ClonePlan,
    WarehouseRatePlanDetail,
    AddRateAdjustment,
    WarehouseStorageFee,
    EditRateAdjustment,
    WarehouseRateAdjustmentStorageFee,
    WarehouseExternalRouteConnecting,
    CreateExternalRouteConnecting,
    WarehouseTranfer,
    WarehouseRateAdjustmentStorageFee,
    WarehouseItemBarcodeIssuesItemContent,
    WarehouseItemBarcodeIssuesListItem,
    WarehouseItemBarcodeIssues,
    //item issue
    TabFormInfo,
    SearchForm,
    ShipmentDetailForm,
    OrderDetailForm,
    
  ],
  exports: [],
  providers: [
    ItemIssueService
  ],
})
export class ModuleCrossDockManagement { }
