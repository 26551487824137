<div *ngIf="totalCount > 0" class="list-container bottom10">
  <nz-table #nzTable [nzData]="listJobs"
    [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
    [nzTotal]="totalCount" [nzFrontPagination]="true"
    [nzShowPagination]="totalCount > pageSize"
    [nzPageSize]="pageSize" [nzSize]="'default'"
    nzBordered="true" nzSize="small" [nzScroll]="{x: '500px', y: '300px'}"
  >
    <ng-template #tplNoData>
      <div *ngIf="!isLoading" class="nodata">
          <i nz-icon nzTheme="outline" nzType="search"></i>
          No data
      </div>
    </ng-template>
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th nzWidth="180px">Route</th>
        <th nzWidth="150px">Type</th>
        <th nzWidth="180px">Status</th>
        <th>Shipment</th>
        <th>Carrier</th>
        <th>Total Cost</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let job of nzTable.data">
        <td nzWidth="180px">
          <div class="flex">
            <a target="_blank" [routerLink]="[routeAdminDispatchList, getJobId(job)]">
              <span nz-tooltip nzTooltipTitle="Go to Dispatch">{{job?.code || ''}}</span>
            </a>
            <a *ngIf="job.bidId" target="_blank" [routerLink]="[routeAdminCarrierSales, job.bidId]" class="left10">
              <div nz-tooltip nzTooltipTitle="Go to Bid"><img src="/assets/svg/goto-bid.svg" alt=""></div>
            </a>
            <a *ngIf="job.code && clientId" class="shipment-header left10 link" (click)="copyRouteTrackingLink(job)">
              <i nz-icon nzType="share-alt" nzTheme="outline" nz-tooltip nzTooltipTitle="Copy tracking link route level"></i>
            </a>
          </div>
        </td>
        <td nzWidth="150px">
          <div>{{getJobType(job)}}</div>
        </td>
        <td nzWidth="180px">
          <div class="route-status {{getJobStatus(job)}}">{{getJobStatus(job)}}</div>
        </td>
        <td>
          <div *ngFor="let shipment of (job.shipments || [])">
            <a *ngIf="shipment.orderId" target="_blank" [routerLink]="[routeAdminOrderList, shipment.orderId]">
              <div>{{showShipmentCode(shipment)}}</div>
            </a>
            <div *ngIf="!shipment.orderId">
              <div style="color: #000">{{showShipmentCode(shipment)}}</div>
            </div>
          </div>
        </td>
        <td>
          <div *ngIf="getCarrierId(job)">
            <div>
              <a target="_blank" [routerLink]="[routeAdminCarrierList, getCarrierId(job)]" style="text-decoration: none;">
                <span>{{getCarrierNameFromJob(job)}}</span>
              </a>
            </div>
            <div class="grey">{{getCarrierMCDot(job)}}</div>
          </div>
          <div *ngIf="!getCarrierId(job)">
            <span>No carrier assigned</span>
          </div>
        </td>
        <td>{{getJobTotalCost(job)}}</td>
      </tr>
    </tbody>
  </nz-table>
</div>