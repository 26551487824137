<div class="warp-selection">
  <div class="group-header">
    <div class="f16 bottom20">
      Sent {{ emailSent }} emails, {{ smsSent }} sms: {{time}} <br/>
      <!-- Opened 70 emails, 70 sms<br/> -->
      Cost: ~{{ cost }}
    </div>
  </div>

  <div class="content">
    <nz-table 
      #nzTable nzBordered="true"
      [nzData]="listData"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)"
    >
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata">
          <i nz-icon nzType="search" nzTheme="outline"></i>No data
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata">
          <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
      </ng-template>
      <thead>
        <tr>
          <th style="max-width: 200px;">
            Carrier Name
            <input 
              nz-input placeholder="Search by Carrier Name" 
              [(ngModel)]="searchText" 
              (ngModelChange)="onSearchTextChanged($event)" 
            />
          </th>
          <th>Results</th>
          <th>Reaction</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td style="max-width: 200px;">{{ item.carrierName }}</td>
          <td>
            <div class="group-status">
              <div class="item-status" *ngIf="item.emailStatus">
                <span nz-icon nzType="mail" nzTheme="outline"></span>
                <span class="status" [ngClass]="$asSentStatusColor(item.emailStatus)">
                  {{ $asSentStatusText(item.emailStatus) }}
                </span>
              </div>
              <div class="item-status" *ngIf="item.smsStatus">
                <span nz-icon nzType="message" nzTheme="outline"></span>
                <span class="status" [ngClass]="$asSentStatusColor(item.smsStatus)">
                  {{ $asSentStatusText(item.smsStatus) }}
                </span>
              </div>
            </div>
          </td>
          <td>
            <div class="group-status">
              <div class="item-status" *ngIf="item.lastSentEmail?.status">
                <span nz-icon nzType="mail" nzTheme="outline"></span>
                <span class="status" [ngClass]="$asSentStatusColor(item.lastSentEmail.status)">
                  {{ $asSentStatusText(item.lastSentEmail.status) }}
                </span>
              </div>
              <div class="item-status" *ngIf="item.lastSentSms?.status">
                <span nz-icon nzType="message" nzTheme="outline"></span>
                <span class="status" [ngClass]="$asSentStatusColor(item.lastSentSms.status)">
                  {{ $asSentStatusText(item.lastSentSms.status) }}
                </span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
