<div>
  <!-- loading -->
  <div *ngIf="shouldShowFirstLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
  <ng-container *ngIf="!shouldShowFirstLoading">
    <ng-container *ngIf="!hasParentCompany() else customerInfo">
      <nz-tabset [(nzSelectedIndex)]="selectedTabIndex">
        <nz-tab nzTitle="Information">
          <ng-container [ngTemplateOutlet]="customerInfo"></ng-container>
        </nz-tab>
        <nz-tab nzTitle="Sub-Account">
          <div [parentId]="model.id" client-sub-account-management></div>
        </nz-tab>
      </nz-tabset>
    </ng-container>
    <ng-template #customerInfo>
      <div class="dashboard-child-container no-padding">
        <!-- content -->
        <div class="form-detail">

          <!-- Customer Information -->
          <div [myModel]="model" class="group no-padding" client-detail-basic-info></div>

          <!-- Parent Company Information -->
         <ng-container *ngIf="hasParentCompany()">
           <div [parentId]="getParentId()" class="group no-padding" client-parent-company-info></div>
         </ng-container>

          <!-- Contact Information -->
          <div [myModel]="model" class="group no-padding" client-detail-contact></div>

          <!-- Payment Information -->
          <div [myModel]="model" class="group no-padding" client-detail-payment></div>

          <!-- Notification recipient -->
          <div [myModel]="model" class="group no-padding" client-detail-notification-recipient></div>

          <!-- Notification recipient -->
          <div [myModel]="model" class="group no-padding" client-detail-auto-report-notification></div>

          <!-- Label Template -->
          <div [myModel]="model" class="group no-padding" client-detail-label-template></div>

          <!-- WARP Assigned Information -->
          <div *ngIf="isAdmin" [myModel]="model" class="group no-padding" client-detail-assigned-information></div>

          <!-- Cross Dock Shipment Information -->
          <div [myModel]="model" class="group no-padding" client-detail-cross-dock-info></div>

          <!-- Location Settings -->
          <ng-container *ngIf="requirePermissions([PermissionCode.location_setting.read])">
            <div [myModel]="model" class="group no-padding" client-detail-location-setting></div>
          </ng-container>

          <!-- Route settings -->
          <div *ngIf="isAdmin" [myModel]="model" class="group no-padding" client-detail-route-settings></div>

          <!-- WARP Rating Settings -->
          <div *ngIf="isFinanceAllowed && model" class="group no-padding">
            <div class="group-header">WARP Rating Settings</div>
            <div class="warp-info no-padding">
              <div [scope]="'PRICING'" [subject]="'CUSTOMER_' + model._id" finance-settings></div>
            </div>
          </div>

          <!-- WARP Quick Order Settings -->
          <div
            *ngIf="isEnableQuickOrder()"
            [model]="model"
            class="group no-padding"
            client-detail-quick-order-setting
          ></div>

          <!-- Customer Logo -->
          <div [myModel]="model" class="group no-padding" client-detail-logo></div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
