import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { DialogsModule } from '@dialogs/dialogs.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { QuickUploadPhotoService } from './quickUploadPhoto.service';
import { QuickUploadPhotos } from './upload-photo-form';
import { ImagePreview } from './image-preview';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { TextDetectionModule } from '../text-detection/module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ScrollingModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTagModule,
    NzSwitchModule,
    NzDropDownModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTypographyModule,
    DialogsModule,
    NgxPanZoomModule,
    TextDetectionModule,
  ],
  declarations: [
    QuickUploadPhotos,
    ImagePreview,
    SafeUrlPipe
  ],
  exports: [
    QuickUploadPhotos,
    ImagePreview,
  ],
  providers: [
    QuickUploadPhotoService
  ]
})
export class QuickUploadPhotosModule {}
