import { Component, Input } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { getLinkDashboard } from "@services/routerlink";
import { ResponseWhenBy } from "@wearewarp/types/rest-api/common";

@Component({
  selector: 'when-by-for-list',
  templateUrl: './view.html',
  // styleUrls: ['./style.scss']
})
export class WhenByForList {
  private _data: ResponseWhenBy;
  @Input() set data(value: ResponseWhenBy) {
    this._data = value;
    this.buildDisplayInfo();
  }
  get data() { return this._data }
  @Input() dateTimeFormat = 'MMM D, YYYY h:mm a';

  public hyperLink: string|undefined;
  public name: string = '';
  public time: string = '';
  public get isDeleted(): boolean { return this.data?.by?.isDeleted == true }

  private buildDisplayInfo() {
    this.hyperLink = this.getHyperLink();
    this.name = this.getName();
    this.time = this.getTime();
  }

  private getHyperLink(): string | undefined {
    if (!this.data?.by?.id) return undefined;
    if (this.isDeleted) return undefined;
    return getLinkDashboard(this.data.by);
  }

  private getName(): string {
    let name = this.data?.by?.name ?? '';
    if (!name && this.data?.selfRegistered) return '[Self Registered]';
    if (this.data?.by?.entity && this.data.by.entity != 'users') {
      const map = {
        clients: 'Customer',
        carriers: 'Carrier',
        drivers: 'Driver'
      };
      const entiryName = map[this.data.by.entity] ?? this.data.by.entity;
      if (entiryName) {
        if (!name) {
          name = 'NO NAME';
        }
        name += ` (${entiryName})`;
      }
    }
    return name;
  }

  private getTime(): string {
    if (!this.data?.when) return '';
    return DateUtil.format(this.data.when, this.dateTimeFormat);
  }



}