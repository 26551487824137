<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div style="display: flex; flex-wrap: wrap; row-gap: 10px;align-items: center;">
    <div class="form-item" style="width: 500px;">
      <search-box #searchBox (doSearch)="doSearch($event)" 
        [placeHolder]="getPlaceHolder('searchKeyword')"
        [searchKeyword]="searchKeyword" style="width: 100%;"
      ></search-box>
    </div>

    <ng-container *ngFor="let key of ['isCreditLimitExceeded']">
      <div class="form-item" style="min-width: 170px;">
        <div class="form-label-v2">{{getLabel(key)}}</div>
        <ng-container [ngSwitch]="key">
          <div *ngSwitchCase="'isCreditLimitExceeded'" nz-checkbox [formControlName]="key"
            (ngModelChange)="onChange($event, key)"
          >Credit Exceeded</div>
          <input *ngSwitchDefault nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)"
            (ngModelChange)="onChange($event, key)"
          />
        </ng-container>
      </div>
    </ng-container>
  </div>
</form>