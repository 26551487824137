<div class="container-box">
  <div class="dashboard-child-container no-padding list">
    <div *ngIf="isAdmin" class="list-header">
      <search-box
        #searchBox (doSearch)="doSearch($event)"
        [searchKeyword]="searchKeyword"
        placeHolder="Search by name or ID"
      ></search-box>
      <div class="flex1"></div>
      <button (click)="onBtnAdd()" *ngIf="requirePermissions([PermissionCode.client.create])" nz-button><i nz-icon nzTheme="outline" nzType="plus"></i>Create
        new customer
      </button>
      <button (click)="onBtnExport()" [nzLoading]="isExporting" nz-button>
        <i nz-icon nzTheme="outline" nzType="file-excel"></i>
        Export
      </button>
    </div>

  <div class="list-body">
    <nz-table
      #nzTable (nzPageIndexChange)="onDataListPageChanged($event)"
      [nzData]="listData" [nzFrontPagination]="false"
      [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading"
      [nzNoResult]="tplNoData"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzShowPagination]="totalCount > listData.length"
      [nzSize]="'default'"
      [nzTotal]="totalCount"
      nzBordered="true"
      nzSize="small"
      [nzScroll]="{y: tableHeight + 'px'}">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="search"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
      <tr>
        <th nzWidth="45px"></th>
        <th [nzSortFn]="true" nzColumnKey="warpId" nzSortOrder="null" nzWidth="120px">WARP ID</th>
        <th [nzSortFn]="true" nzColumnKey="name" nzSortOrder="null" nzWidth="200px">Name</th>
        <th nzWidth="450px">Address</th>
        <th nzWidth="100px">Locations</th>
        <th nzWidth="130px">Sub Accounts</th>
        <th nzWidth="130px">Credit Exceeded</th>
        <th nzWidth="200px">Created</th>
        <th *ngIf="shouldShowDenimOrQuickbooks()" nzWidth="150px">Denim Customer</th>
        <th *ngIf="shouldShowDenimOrQuickbooks()" nzWidth="150px">Quickbooks Customer</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of listData; let i = index">
        <!--        list client-->
        <tr>
          <td [(nzExpand)]="item.expand" style="width: 40px">
            <span *ngIf="item.metadata?.subClients" 
              (click)="item.expand = !item.expand"
              [nzType]="item.expand?'minus':'plus'" nz-icon
              nzTheme="outline"
            ></span>
          </td>
          <td>
            <a [routerLink]="[routeAdminClientList, item.id]" class="inherit">{{item.warpId}}</a>
          </td>
          <td>
            <a [routerLink]="[routeAdminClientList, item.id]">{{item.name}}</a>
          </td>
          <td>{{getAddressText(item.address)}}</td>
          <td>
            <a [routerLink]="[routeAdminClientList, item.id, 'locations']">{{getLocationCount(item)}}</a>
          </td>
          <td>{{item.subClients?.length || ''}}</td>
          <td>{{ item.isCreditLimitExceeded ? 'TRUE' : 'FALSE' }}</td>
          <td>
            <when-by-for-list [data]="item.insert"></when-by-for-list>
          </td>
          <td *ngIf="shouldShowDenimOrQuickbooks()">
            <div>
              <span>{{getDenimInfo(item)}}</span>
              <button title="edit" nz-button nzType="link" (click)="onBtnEditDenim(item)"><i nz-icon nzType="edit"></i></button>
            </div>
          </td>
          <td *ngIf="shouldShowDenimOrQuickbooks()">
            <div>
              <span>{{getQuickbooksInfo(item)}}</span>
              <button title="edit" nz-button nzType="link" (click)="onBtnEditQuickbooks(item)"><i nz-icon nzType="edit"></i></button>
            </div>
          </td>
        </tr>
        <!--        sub client-->
        <tr [nzExpand]="item.expand">
          <nz-table #innerTable [nzData]="item.subClients" [nzShowPagination]="false" nzSize="middle">
            <thead>
            <tr>
              <th nzWidth="120px">Warp ID</th>
              <th nzWidth="200px">Name</th>
              <th nzWidth="450px">Address</th>
              <th nzWidth="100px">Locations</th>
              <th nzWidth="130px">Sub Accounts</th>
              <th nzWidth="130px">Credit Exceeded</th>
              <th nzWidth="200px">Created</th>
              <th *ngIf="shouldShowDenimOrQuickbooks()" nzWidth="150px">Denim Customer</th>
              <th *ngIf="shouldShowDenimOrQuickbooks()" nzWidth="150px">Quickbooks Customer</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let subItem of innerTable.data">
              <td><a [routerLink]="[routeAdminClientList, subItem.id]" class="inherit">{{subItem.warpId}}</a></td>
              <td><a [routerLink]="[routeAdminClientList, subItem.id]">{{subItem.name}}</a></td>
              <td>{{getAddressText(subItem.address)}}</td>
              <td><a [routerLink]="[routeAdminClientList, subItem.id, 'locations']">{{getLocationCount(subItem)}}</a></td>
              <td>{{subItem.subClients?.length || ''}}</td>
              <td>{{ subItem.isCreditLimitExceeded ? 'TRUE' : 'FALSE' }}</td>
              <td>
                <when-by-for-list [data]="subItem.insert"></when-by-for-list>
              </td>
              <td *ngIf="shouldShowDenimOrQuickbooks()">
                <div>
                  <span>{{getDenimInfo(subItem)}}</span>
                  <button title="edit" nz-button nzType="link" (click)="onBtnEditDenim(subItem)"><i nz-icon nzType="edit"></i></button>
                </div>
              </td>
              <td *ngIf="shouldShowDenimOrQuickbooks()">
                <div>
                  <span>{{getQuickbooksInfo(subItem)}}</span>
                  <button title="edit" nz-button nzType="link" (click)="onBtnEditQuickbooks(subItem)"><i nz-icon nzType="edit"></i></button>
                </div>
              </td>
            </tr>
            </tbody>
          </nz-table>
        </tr>
      </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>
