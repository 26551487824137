import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '@services/api.service';
import { BaseComponent } from "@abstract/BaseComponent";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { QuickUploadPhotoService } from "@app/admin/components/quick-upload-photos/quickUploadPhoto.service";
import { PodFormTab } from "@app/admin/components/quick-upload-photos/quickUploadPhoto.interface";
import { ItemIssueService } from "../../ItemIssue.service";

@Component({
  selector: "tab-form-info",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class TabFormInfo extends BaseComponent {
  constructor(
    private itemIssueService: ItemIssueService,
    private quickUploadPhotoService: QuickUploadPhotoService
  ) {
    super();
  }

  public selectedTab: number;
  public tabs: PodFormTab[] = [];
  public formInfoWidth: number = 50; //percent

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.itemIssueService.tabs.subscribe({
      next: index => {
        this.tabs = this.itemIssueService.tabs.getValue();
      }
    }));
    this.subscription.add(this.itemIssueService.activeTabIndex.subscribe({
      next: index => {
        this.selectedTab = index;
      }
    }));
    this.subscription.add(this.quickUploadPhotoService.formInfoWidth.subscribe({
      next: value => {
        this.formInfoWidth = value;
      }
    }));
  }

  onTabChange(tab) {
    this.itemIssueService.setActiveTab(tab.index);
  }
  onTabClose(tab) {
    this.itemIssueService.closeTabByIndex(tab.index);
  }

  onClickAction(action) {
    switch (action) {
      case "closeAllTabs":
        this.closeAllTabs();
        break;
      case "toggleWidth":
        this.toggleFormInfoWidth();
        break;
    }
  }

  toggleFormInfoWidth() {
    this.quickUploadPhotoService.formInfoWidth.next(this.formInfoWidth == 50 ? 80 : 50);
  }
  closeAllTabs() {
    for (let i = this.tabs.length - 1; i >= 0; i--) {
      let tab = this.tabs[i];
      if (!tab.closable) return;
      tab.index = i;
      this.onTabClose(tab);
    }
  }

  onFormInteraction() {
    if (this.formInfoWidth === 50) {
      this.quickUploadPhotoService.formInfoWidth.next(80);
    }
  }
}
