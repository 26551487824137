<!-- <td (nzCheckedChange)="onSelect()" [nzChecked]="selected" (click)="stopPropergate($event)"></td> -->
<td style="text-align: center" [class]="'status-cell ' + _item?.status">
  <nz-tag [nzColor]="getStatusColor(_item?.status)">{{ getIssuesStatus(_item?.status) }}</nz-tag>
</td>

<!-- Warehouse -->
<td>
  <div>{{ getWarehouseName(_item?.warehouseId) || '-' }}</div>
</td>

<!-- WARP Barcode -->
<td>
  <div *ngIf="getListInternalBarcodes(_item).length">
    <code
      *ngFor="let bc of getListInternalBarcodes(_item)"
      class="po-number clickable"
      (click)="copyToClipboard($event, bc)"
    >
      <span>{{ bc }}</span>
      <span class="copy-button clickable" (click)="copyToClipboard($event, bc)">
        <span nz-icon nzType="copy" nzTheme="outline"></span>
      </span>
    </code>
  </div>
  <div *ngIf="!getListInternalBarcodes(_item).length">-</div>
</td>

<!-- External Barcodes -->
<td>
  <div *ngIf="getListBarcodes(_item).length">
    <code
      *ngFor="let bc of getListBarcodes(_item)"
      class="po-number clickable"
      (click)="copyToClipboard($event, bc)"
    >
      <span>{{ bc }}</span>
      <span class="copy-button clickable" (click)="copyToClipboard($event, bc)">
        <span nz-icon nzType="copy" nzTheme="outline"></span>
      </span>
    </code>
  </div>
  <div *ngIf="!getListBarcodes(_item).length">-</div>
</td>

<!-- Type -->
<td style="text-align: center">
  {{ getIssueText(_item.type) }}
</td>

<!-- Shipment -->
<td style="text-align: center">
  {{ _item?.shipment?.code || '-' }}
</td>

<!-- Report Date -->
<td>
  <when-by-for-list [data]="_item.insert"></when-by-for-list>
</td>

