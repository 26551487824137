import { Component, Input } from '@angular/core';
import { Const } from "@const/Const";
import { FormArray } from "@angular/forms";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DialogService } from '@dialogs/dialog.service';
import { LocationSetting } from '@app/admin/clients/location-setting';

@Component({
  selector: "[client-detail-location-setting]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss"
  ],
})
// Location Setting
export class ClientDetailLocationSetting extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    settings: {label: '', type: 'formArray', initialValue: [], childItem: {
      id: { label: '' },
      warehouseId: { label: 'Warehouse' },
      locationType: { label: 'Location Type', initialValue: 'ALL' },
      scanBarcodeRequired: { label: 'Scan Barcode', initialValue: false },
      photoRequired: { label: 'POD', initialValue: false },
      podAtLocationLevel: { label: 'POD', initialValue: true },
      bolRequired: { label: 'BOL', initialValue: false },
      signatureRequired: { label: 'Signature.', initialValue: false },
      isIdVerificationRequired: { label: 'ID Verification.', initialValue: false },
      isAllowPickupMoreItems: { label: 'Allow driver to go with more items.', initialValue: false },
      isAllowPickupLessItems: { label: 'Allow driver to go with less items .', initialValue: false },
      isAllowSearchBarcode: { label: 'Allow search barcode.', initialValue: false },
      useGeoFencing: { label: 'Geofencing feature.', initialValue: null },
      noExpectedBarcode: { label: 'No expected barcode', initialValue: false },
      metadata: { label: 'Metadata' }
    }}
  };

  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    if(currentModel) this.bindDataModel(value);
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormValues();
  }

  async initFormValues() {
    const settings = await this.getLocationSettings(this.model.id);
    this.formGroupDeclaration.settings.initialValue = Object.values(settings);
    this.createFormInput({ ...this.model, settings });
    this.disableEditing();
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
  }

  public isLoading = true;
  private async getLocationSettings(clientId) {
    this.isLoading = true;
    const url = Const.APIV2(`${Const.APIURI_LOCATION_SETTING}/ALL/CUSTOMER_${clientId}`);
    const response = await this.api.GET(url).toPromise();
    const data = response?.data?.list_data || [];
    let result = [];
    for(let item of data) {
      let value = JSON.parse(item.value || '{}');
      result.push({
        id: item.id || null,
        warehouseId: item?.warehouseId,
        locationType: item.scope,
        metadata: item.metadata,
        ...value
      })
    }
    this.isLoading = false;
    return result;
  }

  getValue(index: number, key: string) {
    const item = this.getItemValue(`settings[${index}]`);
    const value = this.getItemValue(`settings[${index}].${key}`);
    switch (key) {
      case 'warehouseId': 
        return value ? item?.metadata?.warehouseName || 'Warehouse' : 'ALL'
      case 'locationType': 
        return value;
      case 'scanBarcodeRequired':
        return value ? 'Required' : '';
      case 'podAtLocationLevel':
        if(!item.photoRequired) return '';
        return value ? 'Required (stop level)' : 'Required (shipment level)';
      case 'bolRequired':
        return value ? 'Required' : '';
      case 'signatureRequired':
        return value ? 'Required' : '';
      case 'isIdVerificationRequired':
        return value ? 'Required' : '';
      case 'isAllowPickupMoreItems':
        return value ? 'Required' : '';
      case 'isAllowPickupLessItems':
        return value ? 'Required' : '';
      case 'isAllowSearchBarcode':
        return value ? 'Required' : '';
      case 'useGeoFencing':
        return value ?? 'No';
      case 'noExpectedBarcode':
        return value ?? 'No'
      default:
        return '';
    }
  }

  addSetting() {
    DialogService.openFormDialog1(LocationSetting, {
      nzComponentParams: {
        headerText: `Add Location Setting`,
        model: null,
        onSave: async data => {
          let item = {
            ...data.settings, 
            locationType: data.locationType, 
            warehouseId: data.warehouseId,
            metadata: {
              ...(data.metadata || {})
            }
          }
          const result = await this.addLocationSetting(item);
          if(result) this.addItemToFormArray('settings', item);
        }
      },
      nzClassName: "modal",
    });
  }

  private addLocationSetting(setting) {
    const url = Const.APIV2(`${Const.APIURI_LOCATION_SETTING}/add-settings`);
    let data = {
      clientId: this.model.id,
      settings: [setting]
    }

    return this.api.POST(url, data).toPromise();
  }

  private updateLocationSetting(id, data) {
    const url = Const.APIV2(`${Const.APIURI_LOCATION_SETTING}/${id}`);

    return this.api.PUT(url, data).toPromise();
  }

  editItemSetting(index) {
    const item = this.getItemValue(`settings[${index}]`);

    DialogService.openFormDialog1(LocationSetting, {
      nzComponentParams: {
        headerText: `Edit Location Setting`,
        model: { 
          settings: item, 
          locationType: item.locationType, 
          warehouseId: item.warehouseId,
          metadata: item.metadata
        },
        onSave: async data => {
          const fc = (<FormArray>this.formInput.get('settings'))?.at(index);
          let param = {
            ...item, ...data.settings, 
            locationType: data.locationType, 
            warehouseId: data.warehouseId,
            metadata: {
              ...(item.metadata || {}),
              ...(data.metadata || {})
            }
          }
          const result = item?.id ? await this.updateLocationSetting(item.id, param) : await this.addLocationSetting(param);
          if (fc && result) fc.setValue(param);
        }
      },
      nzClassName: "modal",
    });
  }

  removeItemSetting(groupKey, index) {
    this.confirmDeletion({
      message: `Are you sure you want to remove this setting?`,
      fnOk: () => {
        this.deleteItemSetting(groupKey, index);
      }
    });
  }

  protected deleteItemSetting(groupKey, index) {
    const item = this.getItemValue(`${groupKey}[${index}]`);
    const url = Const.APIV2(`${Const.APIURI_LOCATION_SETTING}/${item.id}`);
    this.api.DELETE(url).subscribe(
      resp => {
        this.removeItemInFormArray(groupKey, index);
      }, err => {
        this.showErr(err);
      }
    );
  }
}
