import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: '[client-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../app.scss', '../../../dialogs/dialogs.scss']
})
export class ClientFilter extends BaseFormItem {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  public static get declaration(): FormGroupDeclaration {
    return {
      searchKeyword: { label: '', placeHolder: 'Search by name or ID', notAcceptEmpty: true },
      isCreditLimitExceeded: { label: '' },
    }
  };

  protected formGroupDeclaration: FormGroupDeclaration = ClientFilter.declaration;

  public condition: any = {}
  public searchKeyword: string = null
  public loaded: number = 0
  public page: number = 1;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.condition = JSON.parse(p.filter);
        } catch {
          this.condition = {};
        }
      }
      this.searchKeyword = p.search
      this.loaded = p.loaded || 0
      this.page = p.page || 1
    });
  }

  doSearch(s) {
    this.searchKeyword = s
    this.page = 1
    this.loadData()
  }

  onRefresh() {
    this.onReload.emit()
    this.loadData()
  }

  loadData() {
    let q = { page: this.page || 1, loaded: Date.now() }
    if (this.searchKeyword) {
      q['search'] = this.searchKeyword
    }
    const f = JSON.stringify(this.condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    this.routeWithQueryUrl(q)
  }

  onChange(value, key) {
    this.page = 1;
    if (value) {
      this.condition[key] = value;
    } else {
      delete this.condition[key];
    }

    this.loadData();
  }

  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, { search: this.searchKeyword })
    super.ngOnInit();
  }
}
