import { Component, HostListener, EventEmitter } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { BaseList } from "../base/list";
import { MasterData } from '@services/master.data';
import { ApiService } from "@services/api.service";
import { SearchDenimCompany } from "../fin/components/fin-statement/denim/search-denim-company";
import { DialogService } from "@dialogs/dialog.service";
import { SearchQuickbooksCustomer } from "../fin/components/quickbooks/search-quickbooks-customer";
import { CreateQuickbooksCustomer } from "../fin/components/quickbooks/create-customer";

@Component({
  selector: 'client-list',
  templateUrl: './list.html',
  styleUrls: ['../list.scss', './list.client.scss']
})
export class ClientList extends BaseList {
  tableHeight = 400;
  private _topNavOps;
  public get topNavOps(): TopNavOptions { return this._topNavOps }

  constructor() {
    super();
    this._topNavOps = {
      title: 'Shipper Management',
      refreshFn: this.onBtnRefresh.bind(this),
      buttons: []
    }
    if (this.isAdmin) {
      this._topNavOps.buttons.push({ title: 'Create new customer', nzIcon: 'plus', action: this.onBtnAdd.bind(this) });
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.toggleDetailContainerHeight();
  }

  // //window resize thì tính toán lại chiều cao của dispatch window
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.toggleDetailContainerHeight();
  }

  toggleDetailContainerHeight() {
    this.tableHeight = window.innerHeight - 325;
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected confirmDeleteMessage(item): string {
    return `Delete customer <b>${item.name}</b>?`;
  }

  getLocationCount(item) {
    if (Utils.isArrayNotEmpty(item.warehouses)) {
      return item.warehouses.length;
    }
    return 0;
  }
  protected onGetDataSucceeded(resp) {
    this.fetchSubAccounts(this.listData);
    this.fetchListFinAccounts(this.listData);
    for (let item of this.listData) {
      if (item.address?.country) {
        let country = MasterData.getCountryByAlpha2Code(item.address.country);
        if (country?._id) item.address.countryId = country._id;
      }
    }
  }

  public isExporting = false;
  onBtnExport() {
    this.isExporting = true;
    let query = this.prepareParamGetList();
    query.isDownload = true;
    this.api.postExport(`${Const.APIURI_CLIENTS}/export`, query).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

  fetchSubAccounts(clients: any[]) {
    if (clients.length == 0) return;
    const apiUrl = Const.APIV2(`${Const.APIURI_CLIENTS}/sub-clients?${clients.map(client => `clientIds[]=${client.id}`).join("&")}`);
    this.api.GET(apiUrl).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        let isSearching = this.queryParams['search'];
        clients.forEach(client => {
          client.subClients = listData.filter(item => item.metadata?.parentClient === client.id);
          if (isSearching && client.metadata?.subClients) {
            client.expand = true;
          }
        });
        if (listData.length) {
          this.fetchListFinAccounts(listData);
        }
      },
      (err) => { }
    );
  }

  fetchListFinAccounts(clients: any[]) {
    if (!this.shouldShowDenimOrQuickbooks()) return;
    const clientIds = clients.map(client => client.id).filter(x=>x);
    if (!clientIds.length) return;  
    let qs = new URLSearchParams({filter: JSON.stringify({clientIds})}).toString();
    const apiUrl = Const.APIV2(`${Const.APIURI_CLIENTS}/fin-accounts?${qs}`);
    this.api.GET(apiUrl).subscribe(
      (resp) => {
        if (resp?.data?.list_data?.length) {
          const finAccountGroupByClient = resp.data.list_data.reduce((acc, finAccount) => {
            const clientId = finAccount.accountProfile?.entityId || '';
            if (!acc[clientId]) acc[clientId] = [];
            acc[clientId].push(finAccount);
            return acc;
          }, {} as Record<string, any[]>);
          for (let client of this.listData) {
            const finAccounts = finAccountGroupByClient[client.id] || [];
            if (finAccounts.length) {
              client.finAccounts = finAccounts;
            }
            for (let subClient of client.subClients || []) {
              const subFinAccounts = finAccountGroupByClient[subClient.id] || [];
              if (subFinAccounts.length) {
                subClient.finAccounts = subFinAccounts;
              }
            }
          }
        }
      },
      (err) => {}
    );
  }

  onBtnEditDenim(item) {
    const finAccount = this.getFindAccount(item);
    DialogService.openFormDialog1(SearchDenimCompany, {
      nzComponentParams: {
        companyType: 'debtor',
        accountInfo: {
          ...item,
          finAccount
        },
        selectedCompanies: this.getDenimCompanies(item),
        onClose: (reload: boolean) => {
          if (reload) {
            this.getData();
          }
        }
      },
      nzClassName: "modal-lg",
    });
  }

  getDenimInfo(item) {
    const denimCompanies = this.getDenimCompanies(item);
    if (!denimCompanies || denimCompanies.length === 0) return 'N/A';
    const denimInfo = denimCompanies.map(company => company?.company?.company_name).join(', ');
    return denimInfo;
  }

  getDenimCompanies(item) {
    const finAccounts = item.finAccounts || [];
    const receivableAccount = finAccounts.find(acc => acc.type === 'receivable');
    if (!receivableAccount || !receivableAccount.denimPaymentServiceInfo) return [];

    return Array.isArray(receivableAccount.denimPaymentServiceInfo) ? receivableAccount.denimPaymentServiceInfo : [receivableAccount.denimPaymentServiceInfo];
  }

  getFindAccount(item) {
    return item.finAccounts.find(acc => acc.type === 'receivable');
  }

  shouldShowDenimOrQuickbooks() {
    return this.isSysAdmin || this.isAccounting;
  }

  getQuickbooksInfo(item) {
    const finAccounts = item.finAccounts || [];
    const receivableAccount = finAccounts.find(acc => acc.type === 'receivable');
    const quickbooksCustomerInfo = receivableAccount?.quickbooksCustomerInfo;
    if (!quickbooksCustomerInfo) return 'N/A';
    return quickbooksCustomerInfo.CompanyName || 'N/A';
  }

  onBtnEditQuickbooks(item) {
    const finAccount = this.getFindAccount(item);
    let afterClose = new EventEmitter();
    afterClose.subscribe(() => {
      this.getData();
    })
    this.modalService.create({
      nzTitle: 'Search Quickbooks Customer',
      nzContent: SearchQuickbooksCustomer,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzClassName: "modal-lg",
      nzWrapClassName: 'vertical-center-modal',
      nzAfterClose: afterClose,
      nzComponentParams: {
        finAccountId: finAccount?.id,
        accountInfo: {
          name: item.name,
          address: item.address,
        },
        selectedCustomer: finAccount?.quickbooksCustomerInfo,
        onCreateCustomer: () => {
          this.onCreateQuickbooksCustomer({finAccountId: finAccount?.id, name: item.name, address: item.address, primaryContact: item.primaryContact});
        }
      }
    });
  }

  onCreateQuickbooksCustomer(accountInfo) {
    let afterClose = new EventEmitter();
    afterClose.subscribe(() => {
      this.getData();
    })
    this.modalService.create({
      nzTitle: 'Create Quickbooks Customer',
      nzContent: CreateQuickbooksCustomer,
      nzFooter: null,
      nzClosable: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzClassName: "modal-lg",
      nzWrapClassName: 'vertical-center-modal',
      nzAfterClose: afterClose,
      nzComponentParams: {
        accountInfo: accountInfo,
      }
    });
  }
}
