import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { ModelOrderDetail } from '@app/interfaces/order';
import { MasterData } from '@services/master.data';
import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { Utils } from "@services/utils";
import dayjs from 'dayjs';
import { getInjector } from '@services/injector';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Log } from '@services/log';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'checkbox-invoiced',
  templateUrl: './checkbox-invoiced.component.html',
  styleUrls: ['./checkbox-invoiced.component.scss']
})

export class CheckboxInvoicedComponent extends BaseComponent {
  @Input() invoiceIndex: number;
  
  private _model: ModelOrderDetail;
  get model(): ModelOrderDetail {
    return this._model;
  }
  @Input() set model(value: ModelOrderDetail) {
    this._model = value;
    this.extractModelOrder(value);
  }
  @Output() onSaveInvoiceSent = new EventEmitter();
  @Output() onCreateQBInvoice = new EventEmitter();
  @ViewChild('tplNotifyCreateQBInvoice') tplNotifyCreateQBInvoice: TemplateRef<any>;
  isInvoiceSent: boolean = false;
  isLoading: boolean = false;
  invoiceSent: any;
  additionalCosts: any[] = [];
  additionalCost: any = null;

  ngOnInit(): void {
    super.ngOnInit();
  }

  constructor(private notificationService: NzNotificationService) {
    super();
  }

  private extractModelOrder(modelOrder: ModelOrderDetail) {
    this.invoiceSent = modelOrder.invoiceSent;
    this.isInvoiceSent = !!modelOrder.invoiceSent?.when;
    this.additionalCosts = modelOrder.additionalCosts || [];
    if (Utils.isArrayNotEmpty(this.additionalCosts)) {
      for (let additionalCost of this.additionalCosts) {
        if (!additionalCost) continue;
        if (additionalCost.invoiceFileId && !additionalCost.invoice?._id) {
          additionalCost.invoice = { _id: additionalCost.invoiceFileId };
        }
        additionalCost.isInvoiceSent = !!additionalCost.invoiceSent?.when;
      }
    }
    if (this.invoiceIndex>=0) this.additionalCost = this.additionalCosts[this.invoiceIndex];
  }

  canToggleInvoiceSent(): boolean {
    if (this.invoiceSent?.when) {
      // uncheck
      if (this.hasAdditionalCostAtIndex(0)) {
        return false;
      }
      const markWhen = new Date(this.invoiceSent.when).getTime();
      let isValidTimeAllow = Date.now() - markWhen < MasterData.getTimeAllowUncheckInvoiceSent();
      if (isValidTimeAllow) return true;
      const timestampEndOfMonth = this.getTimestampEndOfMonth(new Date(this.invoiceSent.when)).valueOf();
      return Date.now() <= timestampEndOfMonth;
    } else {
      // check
      return this.canBeMarkedAsInvoiceSent();
    }
  }

  private hasAdditionalCostAtIndex(index: number): boolean {
    return this.additionalCosts && this.additionalCosts.length > index;
  }

  // lấy thời điểm cuối cùng của tháng invoice sent theo timezone Mỹ để check disable invoice sent
  // 'Pacific/Pago_Pago';       // GMT-11:00
  getTimestampEndOfMonth(invoiceSentWhen: Date) {
    const when = dayjs(invoiceSentWhen).utc();
    const pacificDate = when.tz('Pacific/Pago_Pago');
    const endOfMonth = pacificDate.endOf('month');
    return endOfMonth.valueOf()
  }

  private canBeMarkedAsInvoiceSent(): boolean {
    if (this.isLoading) {
      return false;
    }
    if (!this.isOrderFinished()) {
      return false;
    }
    if (!this.isOrderHasInvoice()) {
      return false;
    }
    if (this.shouldShowAdditionalInvoice()) {
      return false;
    }
    return true;
  }

  private isOrderFinished(): boolean {
    return this.model.status == WarpConst.OrderStatusNew.completed || this.model.status == WarpConst.OrderStatusNew.canceled;
  }

  private isOrderHasInvoice(): boolean {
    return this.model.invoiceFileId != null;
  }

  shouldShowAdditionalInvoice() {
    if (!this.isInvoiceSent) return false;
    if (!Utils.isArrayNotEmpty(this.additionalCosts)) return false;
    return true;
  }

  onBtnInvoiceSent(value) {
    let isInvoiceSent = !!this.invoiceSent?.when;
    if (value == isInvoiceSent) {
      return;
    }
    const modalService = getInjector().get(NzModalService);
    let title = value ? 'Are you sure you want to mark it as already sent?' : 'Are you sure you want to mark it as not sent yet?';
    modalService.confirm({
      nzTitle: title,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Yes',
      nzOnOk: () => {
        this.saveInvoiceSent(value);
        // chỉ với invoice sent đầu tiên khi tick thì bắn api để tạo Quickbooks invoice, còn 2nd, 3rd, .. thì không xử lý
        if (value) { this.createQBInvoice() }
      },
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.isInvoiceSent = !value;
      }
    });
  }

  private saveInvoiceSent(value: boolean) {
    let url = Const.APIV2(`${Const.APIURI_ORDERS}/${this.model.id}/invoice_sent`);
    this.isLoading = true;
    this.api.PUT(url, { value }).subscribe(
      resp => {
        Log.d('onBtnInvoiceSent resp: ', resp);
        this.isLoading = false;
        this.invoiceSent = resp?.data?.invoiceSent;
        this.isInvoiceSent = !!this.invoiceSent?.when;
        this.onSaveInvoiceSent.emit(this.invoiceSent)
      }, err => {
        this.showErr(err);
        this.isInvoiceSent = !!this.invoiceSent?.when;
        this.isLoading = false;
      }
    );
  }

  private createQBInvoice() {
    let url = Const.APIV2(`${Const.APIURI_ORDERS}/${this.model.id}/qb_invoice`);
    this.api.POST(url).subscribe(
      resp => {
        let mode;
        let message;
        if (resp?.data?.exceptionCode) {
          mode = 'error';
          const { exceptionCode, exceptionData } = resp.data;
          if (exceptionCode == 'FIN_ACCOUNT_NEED_MAPPING_TO_QUICKBOOKS') {
            const clientLink = `${Const.routeAdminClientList}?page=1&search=${exceptionData.clientName}`;
            message = `Customer <b>${exceptionData.clientName}</b> has no mapping account to Quickbooks.<br>Click <a href="${clientLink}" target="_blank">here</a> to map account.`
          } else if (exceptionCode == 'QUICKBOOKS_INVOICE_ALREADY_EXIST') {
            const qbLink = this.getQuickbooksInvoiceLink(exceptionData.invoiceId);
            message = `Invoice no. <b>${exceptionData.invoiceNo}</b> does exist in Quickbooks.<br>Click <a href="${qbLink}" target="_blank">here</a> to view invoice.`
          } else if (exceptionCode == 'QUICKBOOKS_TERM_NOT_FOUND') {
            message = `Term <b>${exceptionData.termName}</b> does not exist in Quickbooks.`  
          } else if (exceptionCode == 'QUICKBOOKS_ITEM_NOT_FOUND') {
            message = `Service <b>${exceptionData.itemName}</b> does not exist in Quickbooks.`
          } else if (exceptionCode == 'QUICKBOOKS_CREATE_INVOICE_FAIL') {
            message = `Send Quickbooks API failed. Please try again later.`
          }
        } else if (resp?.data?.invoiceQBInfo) {
          this.model.invoiceQBInfo = resp.data.invoiceQBInfo;
          this.onCreateQBInvoice.emit(this.model.invoiceQBInfo);
          mode = 'success';
          const qbLink = this.getQuickbooksInvoiceLink(resp.data.invoiceQBInfo.quickbooksInvoiceId);
          message = `View invoice <a href="${qbLink}" target="_blank">${resp.data.invoiceQBInfo.invoiceNo}</a> in Quickbooks.`;
        }
        if (mode) {
          this.notificationService.template(this.tplNotifyCreateQBInvoice, { 
            nzPauseOnHover: true, 
            nzDuration: 10000, 
            nzData: {
              mode,
              message,
            },
            nzStyle: { width: '450px' },
          });
        }
      }, err => {}
    );
  }

  getQuickbooksInvoiceLink(id: string) {
    const baseQBURL = this.isProduction ? 'https://qbo.intuit.com' : 'https://sandbox.qbo.intuit.com';
    return `${baseQBURL}/app/invoice?txnId=${id}`;
  }

  canToggleInvoiceAdditinalSent(): boolean {
    let additionalCost = this.additionalCosts?.[this.invoiceIndex];
    if (additionalCost?.invoiceSent?.when) {
      // uncheck
      if (this.hasAdditionalCostAtIndex(this.invoiceIndex + 1)) {
        return false;
      }
      const markWhen = new Date(additionalCost.invoiceSent.when).getTime();
      let isValidTimeAllow = Date.now() - markWhen < MasterData.getTimeAllowUncheckInvoiceSent();
      if (isValidTimeAllow) return true;
      const timestampEndOfMonth = this.getTimestampEndOfMonth(new Date(this.invoiceSent.when)).valueOf();
      return Date.now() <= timestampEndOfMonth;
    } else {
      // check
      return this.canBeMarkedAsInvoiceAdditinalSent(this.invoiceIndex);
    }
  }

  private canBeMarkedAsInvoiceAdditinalSent(invoiceIndex: number): boolean {
    if (this.isLoading) {
      return false;
    }
    return this.hasAdditionalCostAtIndex(invoiceIndex);
  }

  onBtnAdditionalInvoiceSent(value) {
    let isInvoiceSent = !!this.additionalCost.invoiceSent?.when;
    if (value == isInvoiceSent) {
      return;
    }
    const modalService = getInjector().get(NzModalService);
    let title = value ? 'Are you sure you want to mark it as already sent?' : 'Are you sure you want to mark it as not sent yet?';
    modalService.confirm({
      nzTitle: title,
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOkText: 'Yes',
      nzOnOk: () => {
        this.saveAdditionalInvoiceSent(value);
      },
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.additionalCost.isInvoiceSent = !value;
      }
    });
  }

  private saveAdditionalInvoiceSent(value) {
    let url = Const.APIV2(`${Const.APIURI_ORDERS}/${this.model.id}/additional_invoice_sent`);
    this.isLoading = true;
    this.api.PUT(url, { id: this.additionalCost.id, value }).subscribe(
      resp => {
        this.isLoading = false;
        this.additionalCost.invoiceSent = resp?.data?.additionalCosts?.find(it => it.id == this.additionalCost.id)?.invoiceSent;
        this.additionalCost.isInvoiceSent = !!this.additionalCost.invoiceSent?.when;
      }, err => {
        this.showErr(err);
        this.additionalCost.isInvoiceSent = !!this.additionalCost.invoiceSent?.when;
        this.isLoading = false;
      }
    );
  }
}