import { Component, forwardRef, Inject, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@services/utils';
import { BaseInputComponent } from '../base-custom-input';
import { Const } from '@const/Const';

@Component({
  selector: 'form-location-settings',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormLocationSettings), multi: true }],

})
export class FormLocationSettings extends BaseInputComponent {

  constructor(
    @Inject(Injector) protected injector: Injector,
  ) {
    super(injector)
  }

  ngOnInit(): void {
    super.ngOnInit()
  }

  readonly useGeoFencingType = {
    force: 'force',
    alert: 'alert',
  }

  get value() {
    return this.selected
  }

  set value(value) {
    if (Utils.isBoolean(value?.scanBarcodeRequired)) {
      this.scanBarcodeRequired = value.scanBarcodeRequired;
    }
    if (Utils.isBoolean(value?.signatureRequired)) {
      this.signatureRequired = value.signatureRequired;
    }
    if (Utils.isBoolean(value?.photoRequired)) {
      this.photoRequired = value.photoRequired;
    }
    if (Utils.isBoolean(value?.bolRequired)) {
      this.bolRequired = value.bolRequired;
    }
    if (Utils.isBoolean(value?.podAtLocationLevel)) {
      this.podAtLocationLevel = value.podAtLocationLevel;
    }
    this.useGeoFencing = value?.useGeoFencing ?? null;
    if (Utils.isBoolean(value?.isIdVerificationRequired)) {
      this.isIdVerificationRequired = value.isIdVerificationRequired;
    }
    if (Utils.isBoolean(value?.isAllowPickupMoreItems)) {
      this.isAllowPickupMoreItems = value.isAllowPickupMoreItems;
    }
    if (Utils.isBoolean(value?.isAllowPickupLessItems)) {
      this.isAllowPickupLessItems = value.isAllowPickupLessItems;
    }
    if (Utils.isBoolean(value?.isAllowSearchBarcode)) {
      this.isAllowSearchBarcode = value.isAllowSearchBarcode;
    }
    if (Utils.isBoolean(value?.noExpectedBarcode)) {
      this.noExpectedBarcode = value.noExpectedBarcode;
    }
    this.onValueChange();
  }

  _scanBarcodeRequired = Const.DefaultLocationSettings.scanBarcodeRequired; 
  _signatureRequired = Const.DefaultLocationSettings.signatureRequired; 
  _photoRequired = Const.DefaultLocationSettings.photoRequired; 
  _bolRequired = Const.DefaultLocationSettings.bolRequired; 
  _podAtLocationLevel = Const.DefaultLocationSettings.podAtLocationLevel;
  _useGeoFencing = null;
  _isIdVerificationRequired = Const.DefaultLocationSettings.isIdVerificationRequired;
  _isAllowPickupMoreItems = Const.DefaultLocationSettings.isAllowPickupMoreItems;
  _isAllowPickupLessItems = Const.DefaultLocationSettings.isAllowPickupLessItems;
  _isAllowSearchBarcode = Const.DefaultLocationSettings.isAllowSearchBarcode;
  _noExpectedBarcode = Const.DefaultLocationSettings.noExpectedBarcode;

  disableShipmentLevel = false; // Biến này để kiểm soát việc cho chọn option Shipment Level hay không

  get scanBarcodeRequired() { return this._scanBarcodeRequired }
  set scanBarcodeRequired(value: boolean) {
    this._scanBarcodeRequired = value;
    if (value) {
        this.podAtLocationLevel = true; // Tự động chọn Stop Level khi required scan barcode
        this.disableShipmentLevel = true; // Ko cho chọn Shipmnent level khi required scan barcode
    } else {
        this.disableShipmentLevel = false; // Bật lại Shipment Level khi không required scan barcode
    }
    this.onValueChange();
}

  get signatureRequired() { return this._signatureRequired }
  set signatureRequired(value) {
    this._signatureRequired = value;
    this.onValueChange();
  }

  get photoRequired() { return this._photoRequired }
  set photoRequired(value) {
    this._photoRequired = value;
    this.onValueChange();
  }

  get bolRequired() { return this._bolRequired }
  set bolRequired(value) {
    this._bolRequired = value;
    this.onValueChange();
  }

  get podAtLocationLevel() { return this._podAtLocationLevel }
  set podAtLocationLevel(value) {
    this._podAtLocationLevel = value;
    this.onValueChange();
  }

  get useGeoFencing() { return this._useGeoFencing; }
  set useGeoFencing(value) {
    this._useGeoFencing = value;
    this.onValueChange();
  }

  get isIdVerificationRequired() { return this._isIdVerificationRequired; }
  set isIdVerificationRequired(value) {
    this._isIdVerificationRequired = value;
    this.onValueChange();
  }

  get isAllowPickupMoreItems() { return this._isAllowPickupMoreItems; }

  set isAllowPickupMoreItems(value) {
    this._isAllowPickupMoreItems = value;
    this.onValueChange();
  }

  get isAllowPickupLessItems() { return this._isAllowPickupLessItems; }

  set isAllowPickupLessItems(value) {
    this._isAllowPickupLessItems = value;
    this.onValueChange();
  }

  get isAllowSearchBarcode() { return this._isAllowSearchBarcode; }
  
  set isAllowSearchBarcode(value) {
    this._isAllowSearchBarcode = value;
    this.onValueChange();
  }
  

  get noExpectedBarcode() { return this._noExpectedBarcode; }

  set noExpectedBarcode(value) {
    this._noExpectedBarcode = value;
    this.onValueChange();
  }

  onValueChange() {
    this.selected = {
      scanBarcodeRequired: this.scanBarcodeRequired,
      signatureRequired: this.signatureRequired,
      photoRequired: this.photoRequired,
      bolRequired: this.bolRequired,
      podAtLocationLevel: this.podAtLocationLevel,
      useGeoFencing: this.useGeoFencing,
      isIdVerificationRequired: this.isIdVerificationRequired,
      isAllowPickupMoreItems: this.isAllowPickupMoreItems,
      isAllowPickupLessItems: this._isAllowPickupLessItems,
      isAllowSearchBarcode: this._isAllowSearchBarcode,
      noExpectedBarcode: this._noExpectedBarcode,
    }
    this.onChange(this.selected);
    this.onTouch(this.selected);
  }

}
